import React from "react";
import { getStaticAssetPath } from "../../../utils/utils";

interface Props {
  name: string;
  location?: string;
  image: string;
  type: string;
  url: string;
  visualization: 'canvas' | 'opportunity'
}

const StudentProCanvasTile: React.FC<Props> = (props) => {
  const { image, name, location, type, url, visualization = 'canvas' } = props;
  const openPortfolio = () => {
    window.location.href = url;
  }
  const displayName = visualization === 'canvas' ? name : name.toUpperCase();
  const actionLabel = visualization === 'canvas' ? 'Launch Portfolio' : 'View Opportunity';
  return (
    <div className="cm-sp-canvas-tile cm-flex-column" onClick={() => openPortfolio()}>
      <div className="cm-flex-1">
        <div className="cm-sp-canvas-tile-img" style={{ backgroundImage: `url(${image})` }} />
      </div>
      <div className="cm-sp-canvas-tile__footer">
        <h4>{displayName}</h4>
        {visualization === 'canvas' && <h5>{location}</h5>}
      </div>
      <div className="cm-sp-canvas-tile-overlay">{actionLabel}</div>
      {visualization === 'canvas' && <div className="cm-sp-canvas-tile-type">{type}</div>}
      {visualization === 'opportunity' && <div className="cm-sp-canvas-tile-type--opportunity">{type}</div>}
      <img className="cm-sp-canvas-ribbon" src={getStaticAssetPath(`studentpro-landing/symbol-with-background.png`)} alt="BEAUTISTA symbol" />
    </div>
  )
};

export default StudentProCanvasTile;
