import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { searchSchool } from "../api/Options";
import Close from "../assets/images/ic_close.svg";
import "./component_add_award_modal.less";

interface Props {
  className?: string;
  visible?: boolean;
  onClose: Function;
  onAdd: Function;
  data?: any;
  business?: boolean;
}

const titleOpt = [
  {
    name: "Beacon",
    issuer: "Beacon",
  },
  {
    name: "Biolage Scalp Certified Specialist",
    issuer: "Biolage",
    image: "biolage.svg",
    imgWidth: 100,
  },
  {
    name: "Caper",
    issuer: "Paul Mitchell Caper",
  },
  {
    name: "Club/Teams",
    issuerLabel: "Club/Team Name",
    yearLabel: "Participated since",
  },
  {
    name: "Dean's List",
    schoolIssuer: true,
  },
  {
    name: "Honors",
  },
  {
    name: "L’Oreal Professionnel/NAMI Head Up Completion",
    issuer: "L’Oreal",
    image: "heads-up-badge.png",
    imgWidth: 200,
  },
  {
    name: "NAHA",
    issuer: "NAHA",
  },
  {
    name: "Purpose Discovery/EQ 101",
    issuer: "BEAUTISTA",
    image: "defining-your-purpose-badge.svg",
    imgWidth: 100,
  },
  {
    name: "Redken Safe Space Educated",
    issuer: "Redken",
    image: "redken.png",
    imgWidth: 100,
    link: 'https://u.canvasme.com/course/safe-space/',
  },
  {
    name: "Scholarship",
  },
  {
    name: "Other",
    specify: true,
  },
] as any[];
export const awardOpts = titleOpt.slice(0, titleOpt.length - 1)

const AddAwardModal: React.FC<Props> = (props) => {
  const { business, className, visible, onClose, onAdd, data } = props;
  const [title, setTitle] = useState({} as any);
  const [specifyTitle, setSpecifyTitle] = useState("");
  const [issuer, setIssuer] = useState(data.issuer || "");
  const [year, setYear] = useState(data.issueYear || "");
  const [showTitleError, setShowTitleError] = useState(false);
  const [showSpecifyTitleError, setShowSpecifyTitleError] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState([]);

  const searchSchoolByName = async (keywords: string) => {
    if (!keywords) {
      setSchoolOptions([]);
      return;
    }
    const result = await searchSchool(keywords, [2, 20]);
    if (result && !_.isEmpty(result.schools)) {
      setSchoolOptions(result.schools);
    }
  };

  useEffect(() => {
    if (title!.issuer) {
      setIssuer(title.issuer);
    }
  }, [title]);

  useEffect(() => {
    if (data) {
      if (data.title) {
        let item = _.find(titleOpt, { name: data.title });
        if (!item) {
          item = _.last(titleOpt);
        }
        setTitle(item);
      }
      if (data.issuer) {
        setIssuer(data.issuer);
      }
      if (data.issueYear) {
        setYear(data.issueYear);
      }
    }
  }, [data]);

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      centered={true}
      wrapClassName="add-award-modal-wrap"
    >
      <div className="add-award-modal">
        <img className="close" src={Close} alt="" onClick={() => onClose()} />
        <span className="title">Add awards & achievements</span>
        <TextField
          select
          key="title"
          label="Title"
          variant="filled"
          helperText={showTitleError ? "Title is required" : ""}
          error={showTitleError}
          value={title.name ? title : ""}
          onChange={(e: any) => {
            setShowTitleError(false);
            setTitle(e.target.value);
            if (e.target.value.name !== title.name) {
              setIssuer("");
            }
          }}
          className="material-input input"
        >
          {business ? (
            _.map(titleOpt, (item, index) => {
              if (item.name.includes("Redken Safe Space Educated") || 
                  item.name.includes("L’Oreal Professionnel/NAMI Head Up Completion") || 
                  item.name.includes("Purpose Discovery Completion") ||
                  item.name.includes("Purpose Discovery/EQ 101") ||
                  item.name.includes("Other")) {
                return (
                  <MenuItem key={index} value={item}>
                    {item.name}
                  </MenuItem>
                );
              }
            })
          ) : (
            _.map(titleOpt, (item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
              </MenuItem>
            ))
          )}
        </TextField>
        {title && title.specify && (
          <TextField
            key="specify-tittle "
            label="Specify Title "
            helperText={showSpecifyTitleError ? "Title is required" : ""}
            error={showSpecifyTitleError}
            variant="filled"
            value={specifyTitle}
            className="material-input input"
            onChange={(e) => {
              setShowSpecifyTitleError(false);
              setSpecifyTitle(e.target.value);
            }}
          />
        )}
        {title && title.schoolIssuer ? (
          <Autocomplete
            freeSolo
            className="material-input input"
            options={schoolOptions}
            value={issuer}
            getOptionLabel={(option) => {
              return option.name || option;
            }}
            onChange={(event: object, value: any, reason: string) => {
              let newItem = value;
              if (typeof newItem === "string") {
                newItem = { name: value };
              }
              setIssuer((newItem && newItem.name) || "");
            }}
            onInputChange={(event: object, value: string, reason: string) => {
              if (issuer !== value) {
                searchSchoolByName(value);
                setIssuer(value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Issuer"
                variant="filled"
                className="material-input"
              />
            )}
          />
        ) : (
          <TextField
            key="issuer"
            disabled={title && title.issuer}
            label={(title && title.issuerLabel) || "Issuer"}
            value={(title && title.issuer) || issuer}
            variant="filled"
            className="material-input input"
            onChange={(e) => {
              setIssuer(e.target.value);
            }}
          />
        )}

        <TextField
          key="year"
          label={(title && title.yearLabel) || "Issue year"}
          variant="filled"
          value={year}
          type="number"
          className="material-input input"
          onChange={(e) => {
            const value = e.target.value;
            if (Number(value) < 0) return;
            setYear(value);
          }}
          onKeyDown={(e) =>
            (e.keyCode === 69 ||
              e.keyCode === 187 ||
              e.keyCode === 189 ||
              e.keyCode === 190) &&
            e.preventDefault()
          }
        />
        <Button
          className="btn-primary btn-add"
          onClick={() => {
            if (!title) {
              setShowTitleError(true);
            } else if (title.name === "Other" && !specifyTitle) {
              setShowSpecifyTitleError(true);
            } else {
              const titleText = title.specify ? specifyTitle : title.name;

              onAdd({
                uuid: data.uuid || "",
                title: titleText,
                issuer: issuer,
                issueYear: year,
              });
            }
          }}
        >
          Add
        </Button>
      </div>
    </Modal>
  );
};
export default AddAwardModal;
