import React from 'react'
import { isEmailValid, isPasswordValid } from "../../utils/validator";
import { onClickGoogleLogin } from "../../utils/googleLogin";
import IconFb from "../../assets/images/ic_fb.png";
import LogoBlack from "../../assets/images/logo.svg";
import IconGoogle from "../../assets/images/ic_google.svg";
import { Button, message, Checkbox, Spin } from 'antd';
import { doAction, mapProp, KV, doFunction } from 'module-reaction';
// import { jumpToEmailPage } from '../../models/model_sign_up';
import { InputAdornment, TextField } from '@material-ui/core';
import _ from 'lodash';
import { router_sign_in, TargetType } from '../../utils/enum';
import MenuItem from "@material-ui/core/MenuItem";
import './comp_sign_up.less'

import { module_get_started, SignUpAction, MODULE_GET_STARTED, CompleteUserInfoAction, FinishVerifyCodeAction, SkipVerifyAction } from '../../models/model_get_started';
import { module_home } from '../../models/model_home';
import { fetchVerifyCode } from '../../api/Auth';
import { modle_user } from '../../models/model_user';
import { getJobTitlesFromTarget, getStartedOauthParams, sessionStorageSet } from '../../utils/utils';
import InputMask from 'react-input-mask';
import Joi from "@hapi/joi";
import classnames from "classnames";
import store, { setGetStartedButtonClickedTrue, setGetStartedButtonClickedFalse } from '../../utils/store';

import { connect } from 'react-redux';

// deprecated, see : NewSignUpEmailPage
export class SignUpMainPage extends React.Component<any, any> {
    state = { policyChecked: false }
    private onFbLoginClick = () => {
        (window as any).FB &&
            (window as any).FB.login(
                (response: any) => {
                    if (response.status !== "connected" || !response.authResponse) {
                        return message.error("Login Failure");
                    }
                    (window as any).FB.api(
                        "/me",
                        { fields: "last_name,first_name,email,picture" },
                        (responseMe: any) => {
                            if (responseMe) {
                                const profileParams = {
                                    platformType: "facebook",
                                    platformUserId: responseMe.id,
                                    email: responseMe.email,
                                    fullName: `${responseMe.first_name} ${responseMe.last_name}`,
                                    avatar: responseMe.picture.data.url || "",
                                };
                                doAction(SignUpAction, profileParams);

                            }
                        }
                    );
                },
                { scope: "public_profile,email" }
            );
    };

    private googleLoginCb = (profile: any) => {
        if (profile) {
            const profileParams = {
                platformType: "google",
                platformUserId: profile.userId,
                email: profile.email,
                fullName: profile.name,
                avatar: profile.profilePhotoUrl,
            };
            doAction(SignUpAction, profileParams);

        }
    };

    render() {

        return (
            <div className="main">
                <p className="title">
                    Create your free account!
                </p>
                <div className="btn-wrap" hidden>
                    <Button
                        className="btn-primary btn-third-party"
                        onClick={() => this.onFbLoginClick()}
                        disabled={!this.state.policyChecked}
                    >
                        <img alt="" src={IconFb} />
                  Sign up with Facebook
                </Button>
                    <Button
                        className="btn-primary btn-third-party"
                        onClick={() => onClickGoogleLogin(this.googleLoginCb)}
                        disabled={!this.state.policyChecked}
                    >
                        <img alt="" src={IconGoogle} />
                  Sign up with Google
                </Button>
                </div>
                <div className="divider" hidden>
                    <span className="stash" />
                or
                <span className="stash" />
                </div>
                <Button
                    className="btn-secondary btn-email"
                    onClick={() => doAction(MODULE_GET_STARTED, { pageName: 'signup_email' })}
                    disabled={!this.state.policyChecked}
                >
                    Register via email
              </Button>
                <div className="footer-text">
                    <Checkbox
                        className="box-item-check"
                        checked={this.state.policyChecked}
                        onClick={() => this.setState({ policyChecked: !this.state.policyChecked })}
                    />
                    <span>
                        By checking the box to the left, I represent and warrant that I have carefully reviewed the linked <a href="terms">Terms of use</a> and <a href="privacy-policy">Privacy policy</a>, and I consent to both. I acknowledge that in so approving I am entering into a binding agreement between myself and Beautiful Canvas, LLC d/b/a BEAUTISTA.
                    </span>
                </div>

            </div>
        );
    }
};

// deprecated, see : NewSignUpEmailPage
@mapProp(module_get_started)
export class SignUpEmailPage extends React.Component<any, any> {
    state = {
        email: '',
        password: '',
        isEmailValid: true,
        isPwdValid: true
    }
    private onEmailInput = (email: string) => {
        let isValid = true;
        if (!_.isEmpty(email)) {
            isValid = isEmailValid(email);
        }
        this.setState({ email: email, isEmailValid: isValid });
    };

    private onPasswordInput = (password: string) => {
        let isValid = true;
        if (!_.isEmpty(password)) {
            isValid = isPasswordValid(password);
        }
        this.setState({
            ...this.state,
            password: password,
            isPwdValid: isValid,
        });
    };

    private onSignUp() {
        const params = {
            platformType: "internal",
            email: this.state.email,
            password: this.state.password,
        };
        doAction(SignUpAction, params)
    }
    render() {

        return (
            <div className="email">
                <img alt="" src={LogoBlack} />
                <p className="title">Register via email</p>
                <TextField
                    key="email"
                    label="Email"
                    error={!this.state.isEmailValid}
                    variant="filled"
                    className="material-input input"
                    placeholder="Enter your email"
                    helperText={
                        this.state.isEmailValid ? "" : "Please enter correct email address."
                    }
                    onChange={(e) => this.onEmailInput(e.target.value)}
                    onKeyPress={e => {
                        if (e.charCode === 13 &&
                            this.state.isEmailValid &&
                            !_.isEmpty(this.state.email) &&
                            this.state.isPwdValid &&
                            !_.isEmpty(this.state.password)
                        ) {
                            this.onSignUp()
                        }
                    }}
                />
                <TextField
                    key="password"
                    label="Password"
                    variant="filled"
                    error={!this.state.isPwdValid}
                    className="material-input input"
                    placeholder="Enter your password"
                    helperText={
                        this.state.isPwdValid
                            ? ""
                            : "Password must be more than 8 characters long, should contain at leat 1 uppercase, 1 lowercase, 1 numeric and 1 special character."
                    }
                    onChange={(e) => this.onPasswordInput(e.target.value)}
                    type="password"
                    onKeyPress={e => {
                        if (e.charCode === 13 &&
                            this.state.isEmailValid &&
                            !_.isEmpty(this.state.email) &&
                            this.state.isPwdValid &&
                            !_.isEmpty(this.state.password)
                        ) {
                            this.onSignUp()
                        }
                    }}
                />
                <Button
                    className="btn-primary btn-sign-up"
                    onClick={() => this.onSignUp()}
                    disabled={
                        !(
                            this.state.isEmailValid &&
                            !_.isEmpty(this.state.email) &&
                            this.state.isPwdValid &&
                            !_.isEmpty(this.state.password)
                        )
                    }
                >
                    Sign up
            </Button>
                <div className="space" />
                {/* <div className="footer-text">
                    By signing up you agree to BEAUTISTA’s{" "}
                    <a href="www.google.com">Anti-spam policy</a>,
                </div>
                <div className="footer-text">
                    <a href="www.google.com">Privacy policy</a> and{" "}
                    <a href="www.google.com">Terms of use</a>
                </div> */}
            </div>
        );
    }
};

@mapProp(module_get_started)
class NewSignUpEmailPage extends React.Component<any, any> {
    state = {
        email: '',
        password: '',
        isEmailValid: true,
        isPwdValid: true,
        policyChecked: false
    }
    private onEmailInput = (email: string) => {
        let isValid = true;
        if (!_.isEmpty(email)) {
            isValid = isEmailValid(email);
        }
        this.setState({ email: email, isEmailValid: isValid });
    };

    private onPasswordInput = (password: string) => {
        let isValid = true;
        if (!_.isEmpty(password)) {
            isValid = isPasswordValid(password);
        }
        this.setState({
            ...this.state,
            password: password,
            isPwdValid: isValid,
        });
    };

    private urlParams = new URLSearchParams(window.location.search);

    private async onSignUp() {
        this.props.setGetStartedButtonClickedTrue();
        const storeValue = store.getState().landingPage.visitedLandingPage;
        const params = {
            platformType: "internal",
            email: this.state.email,
            password: this.state.password,
            source: this.urlParams.get('source') ? this.urlParams.get('source') : storeValue.source,
            rep: this.urlParams.get('rep') ? this.urlParams.get('rep') : storeValue.rep,
        };
        doAction(SignUpAction, params)
    }

    componentDidMount(): void {
        this.props.setGetStartedButtonClickedFalse();
        if (this.props.prefilled) {
            this.setState({...this.state, email: this.props.prefilled});
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.prefilled !== this.props.prefilled) {
            this.setState({...this.state, email: this.props.prefilled});
        }
    }

    passwordSchema = Joi.object({
        oneLower: Joi.string().pattern(/[a-z]/),
        oneUpper: Joi.string().pattern(/[A-Z]/),
        oneNumber: Joi.string().pattern(/[0-9]/),
        oneSpecial: Joi.string().pattern(/[!-\/:-@[-`{-~]/),
        minChar: Joi.string().min(8),
    });

    isPasswordValid = (password: string) => {
        const result = this.passwordSchema.validate({ [password]: this.state.password });
        return result.error ? false : true;
      };


    render() {

        return (
            <div className="email">
                <p className="title">
                    Create your free account!
                </p>
                <TextField
                    key="email"
                    label="Email"
                    error={!this.state.isEmailValid}
                    variant="filled"
                    className="material-input input"
                    placeholder="Enter your email"
                    helperText={
                        this.state.isEmailValid ? "" : "Please enter correct email address."
                    }
                    onChange={(e) => this.onEmailInput(e.target.value)}
                    value={this.state.email}
                    onKeyPress={e => {
                        if (e.charCode === 13 &&
                            this.state.isEmailValid &&
                            !_.isEmpty(this.state.email) &&
                            this.state.isPwdValid &&
                            !_.isEmpty(this.state.password) && this.state.policyChecked
                        ) {
                            this.onSignUp()
                        }
                    }}
                />
                <TextField
                    key="password"
                    label="Password"
                    variant="filled"
                    error={!this.state.isPwdValid}
                    className="material-input input"
                    placeholder="Enter your password"
                    // helperText={
                    //     this.state.isPwdValid
                    //         ? ""
                    //         : "Password must be more than 8 characters long, should contain at leat 1 uppercase, 1 lowercase, 1 numeric and 1 special character."
                    // }
                    onChange={(e) => this.onPasswordInput(e.target.value)}
                    type="password"
                    onKeyPress={e => {
                        if (e.charCode === 13 &&
                            this.state.isEmailValid &&
                            !_.isEmpty(this.state.email) &&
                            this.state.isPwdValid &&
                            !_.isEmpty(this.state.password) && this.state.policyChecked
                        ) {
                            this.onSignUp()
                        }
                    }}
                />
                <div className="pw-criteria-shell">
                    <div className="pw-criteria">
                        <span
                        className={classnames(
                            "pw-criteria-item",
                            this.isPasswordValid("oneLower") ? "match" : "no-match"
                        )}
                        >
                        {this.isPasswordValid("oneLower") ? <>✅</> : <>❌</>}one
                        lowercase character
                        </span>
                        <span
                        className={classnames(
                            "pw-criteria-item",
                            this.isPasswordValid("oneUpper") ? "match" : "no-match"
                        )}
                        >
                        {this.isPasswordValid("oneUpper") ? <>✅</> : <>❌</>}one
                        uppercase character
                        </span>
                        <span
                        className={classnames(
                            "pw-criteria-item",
                            this.isPasswordValid("oneNumber") ? "match" : "no-match"
                        )}
                        >
                        {this.isPasswordValid("oneNumber") ? <>✅</> : <>❌</>}one number
                        </span>
                    </div>
                    <div className="pw-criteria">
                    <span
                        className={classnames(
                            "pw-criteria-item",
                            this.isPasswordValid("oneSpecial") ? "match" : "no-match"
                        )}
                        >
                        {this.isPasswordValid("oneSpecial") ? <>✅</> : <>❌</>}one
                        special character
                        </span>
                        <span
                        className={classnames(
                            "pw-criteria-item",
                            this.isPasswordValid("minChar") ? "match" : "no-match"
                        )}
                        >
                        {this.isPasswordValid("minChar") ? <>✅</> : <>❌</>}8 character
                        minimum
                        </span>
                    </div>
                </div>
                <div className="footer-text">
                    {/* <Checkbox
                        className="box-item-check"
                        checked={this.state.policyChecked}
                        onClick={() => this.setState({ policyChecked: !this.state.policyChecked })}
                    /> */}
                    <span>
                        By creating an account, you agree to our <a href="terms">Terms of Service</a> and have read and understood the <a href="privacy-policy">Privacy policy.</a>
                    </span>
                </div>
                <Button
                    className="btn-primary btn-sign-up"
                    onClick={() => this.onSignUp()}
                    disabled={
                        !(
                            this.state.isEmailValid &&
                            !_.isEmpty(this.state.email) &&
                            this.state.isPwdValid &&
                            !_.isEmpty(this.state.password) &&
                            !this.props.getStartedButtonClicked
                        )
                    }
                >
                    GET STARTED { this.props.getStartedButtonClicked && <Spin /> }
            </Button>
                <div className="sign-up">
                    Already a member?
                    <span className="sign-up-btn"
                        onClick={() => {
                          const searchParams = getStartedOauthParams()
                          this.props.history.push(`${router_sign_in}${searchParams}`)
                        }}
                    >
                        Sign in now
                    </span>
                </div>
                <div className="space" />
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    getStartedButtonClicked: state.landingPage.getStartedButtonClicked,
  });
  
  const mapDispatchToProps = {
    setGetStartedButtonClickedTrue,
    setGetStartedButtonClickedFalse,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(NewSignUpEmailPage);

@mapProp(module_home, 'user')
@mapProp(module_get_started, 'jobTitle')
export class SignUpProfilePage extends React.Component<KV, KV> {
    state = { firstName: '', lastName: '', jobTitle: '', phone: '', zipCode: '', isBusy: false }

    render() {
        const { user: { profile }, history } = this.props
        const firstName = this.state.firstName || profile.firstName || ''
        const lastName = this.state.lastName || profile.lastName || ''
        const phone = this.state.phone || profile.phoneNumber || ''
        return (
            <div className="info person-info">
                <Button
                    className="btn-secondary btn-back"
                    icon="left"
                    onClick={e => {
                        doAction(MODULE_GET_STARTED, { pageName: 'join' })
                    }}
                >
                    Back
                </Button>
                <img alt="" src={LogoBlack} />
                <p className="title">Personal information</p>
                <div className="name-sec">
                    <TextField
                        key="first-name"
                        label="First Name"
                        variant="filled"
                        value={firstName}
                        className="material-input input-name"
                        onChange={(e) =>
                            this.setState({ firstName: e.target.value })
                        }
                        onKeyDown={(e) => e.keyCode === 191 && e.preventDefault()}
                    />
                    <TextField
                        key="last-name"
                        label="Last Name"
                        variant="filled"
                        value={lastName}
                        className="material-input input-name"
                        onChange={(e) =>
                            this.setState({ lastName: e.target.value })
                        }
                        onKeyDown={(e) => e.keyCode === 191 && e.preventDefault()}
                    />
                </div>

                <TextField
                    select
                    key="job-title"
                    label="Job Title"
                    variant="filled"
                    value={this.state.jobTitle}
                    onChange={(e) =>
                        this.setState({ jobTitle: e.target.value })
                    }
                    className="material-input input-title"
                    hidden={this.props.user.profile.target === TargetType.STUDENT}
                >
                    {_.map(getJobTitlesFromTarget(this.props.jobTitle, this.props.user.profile.target), (item, index) => {
                        return (
                            <MenuItem key={index} value={item.name as string}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </TextField>

                <TextField
                    key="zip-code"
                    label="Zip Code"
                    variant="filled"
                    value={this.state.zipCode}
                    onChange={(e) => {
                        const input = e.target.value;
                        if (/^[a-zA-Z\d]{0,20}$/.test(input)) {
                            this.setState({ zipCode: input });
                        }
                    }}
                    className="material-input input-title"
                />

                <InputMask
                    mask="999-999-9999"
                    value={phone}
                    onChange={(e: any) => this.setState({ phone: e.target.value.replace(/\D/g,'') })}
                >
                    {() => (
                        <TextField
                            key="phone-number"
                            label="Mobile Number"
                            variant="filled"
                            className="material-input input-phone"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><span className="phone-pre">+1</span></InputAdornment>,
                            }}
                        />
                    )}
                </InputMask>

                <Button
                    className="btn-primary btn-next-to"
                    disabled={this.state.isBusy ||
                        !(
                            firstName && lastName && phone &&
                            (this.state.jobTitle || this.props.user.profile.target === TargetType.STUDENT)
                        )
                    }
                    onClick={() => this.updateUserInfo()}
                >
                    Send My Text Verification Code
                </Button>
                <div className="space" />
                <Button
                    type="link"
                    disabled={
                        !(
                            firstName && lastName && phone &&
                            (this.state.jobTitle || this.props.user.profile.target === TargetType.STUDENT)
                        )
                    }
                    className="btn-next-to btn-skip"
                    onClick={() => this.updateUserInfo(true)}
                >
                    Skip for now
                </Button>
                {/* <div className="footer-text">
                    By signing up you agree to BEAUTISTA’s{" "}
                    <a href="www.google.com">Anti-spam policy</a>,
                </div>
                <div className="footer-text">
                    <a href="www.google.com">Privacy policy</a> and{" "}
                    <a href="www.google.com">Terms of use</a>
                </div> */}
                <div className="footer-btn" hidden>
                    <Button
                        type="link"
                        disabled={
                            !(
                                firstName && lastName && phone &&
                                (this.state.jobTitle || this.props.user.profile.target === TargetType.STUDENT)
                            )
                        }
                        className="btn-next-to btn-skip"
                        onClick={() => this.updateUserInfo(true)}
                    >
                        Skip for now
                    </Button>
                </div>
            </div>
        );
    }

    private updateUserInfo = (skipVerify = false) => {
        const firstName = this.state.firstName || this.props.user.profile.firstName || ''
        const lastName = this.state.lastName || this.props.user.profile.lastName || ''
        const zipCode = this.state.zipCode || this.props.user.profile.zipCode || ''
        const email = this.props.user.email || ''

        const job = _.find(this.props.jobTitle, { name: this.state.jobTitle });
        let params = {
            firstName,
            lastName,
            zipCode
        } as any;
        if (job && job.id) {
            params.jobTitleId = job.id
        }
        if (this.state.phone) {
            params.phoneNumber = this.state.phone
        }
        this.setState({...this.state, ...{ isBusy: true }})
        doAction(CompleteUserInfoAction, {
            params: params,
            skipVerify,
            email
        });
        doFunction(async () => this.setState({...this.state, ...{ isBusy: false }}))
    };
};

@mapProp(modle_user, 'curUser')
export class SignupVerifyPage extends React.Component<KV, KV> {
    state = { phone: null as any, code: '', isBusy: false }

    static getDerivedStateFromProps(props: KV, prevState: KV) {
        if (prevState.phone === null && props.curUser?.profile?.phoneNumber) {
            const phone = props.curUser.profile.phoneNumber
            prevState.phone = phone
            fetchVerifyCode(phone)
        }
        return prevState
    }
    render() {
        if (!this.props.curUser?.profile) {
            return <Spin />
        }
        const { phone, code } = this.state
        return <div className="info verify-info">
            <img alt="" src={LogoBlack} />
            <p className="title">We sent you a text!</p>
            <div className="name-sec">
            <InputMask
                mask="999-999-9999"
                value={phone}
                onChange={(e) =>
                    this.setState({ phone: e.target.value.replace(/\D/g,'') })
                }
            >
                {() => (
                    <TextField
                        key="phone-txt"
                        label="We sent verification code to "
                        variant="filled"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><span className="phone-pre">+1</span></InputAdornment>,
                        }}
                        className="material-input input-name input-verify"
                        onKeyDown={(e) => e.keyCode === 191 && e.preventDefault()}
                    />
                )}
            </InputMask>
                <TextField
                    key="code-txt"
                    label="CODE"
                    variant="filled"
                    value={code}
                    className="material-input input-name input-verify"
                    onChange={(e) =>
                        this.setState({ code: e.target.value })
                    }
                    onKeyDown={(e) => e.keyCode === 191 && e.preventDefault()}
                />
            </div>
            <Button
                className="btn-primary btn-next-to btn-verify"
                disabled={this.state.isBusy ||
                    !(
                        phone && code
                    )
                }
                onClick={() => this.verifyCode()}
            >
                Confirm
                </Button>

            <Button
                className="btn-secondary btn-resend"
                disabled={this.state.isBusy || !phone}
                onClick={e => this.fetchCode(true)}
            >
                Resend verification code
                </Button>
            <div className="space" />
            <Button
                type="link"
                className="btn-next-to btn-skip"
                onClick={() => this.skipVerify()}
            >
                Skip for now
            </Button>
            <div className="footer-text">
                By signing up you agree to BEAUTISTA’s{" "}
                <a href="www.google.com">Anti-spam policy</a>,
            </div>
            <div className="footer-text">
                <a href="/privacy-policy">Privacy policy</a> and{" "}
                <a href="/terms">Terms of use</a>
            </div>
        </div>
    }

    async verifyCode() {
        const { phone, code } = this.state
        this.setState({...this.state, ...{ isBusy: true }})
        doAction(FinishVerifyCodeAction, { phone, code, target: this.props.curUser.profile.target })
        doFunction(async () => this.setState({...this.state, ...{ isBusy: false }}))
    }

    async skipVerify() {
        doAction(SkipVerifyAction, { phone: this.state.phone, target: this.props.curUser.profile.target })
    }
    async fetchCode(resend?: boolean) {
        const { phone } = this.state
        this.setState({...this.state, ...{ isBusy: true }})
        const res = await fetchVerifyCode(phone, resend)
        this.setState({...this.state, ...{ isBusy: false }})
        if (res?.success) {
            message.info('The verification code is sent!')
        }
    }
}