import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { TablePagination, IconButton } from "@material-ui/core";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import ConfirmModal from "../../../components/component_confirm_modal";
import EditNoteModal from "./edit_note_modal";
import CanvasBookmarkItem from "./canvas_bookmark_item";
import OpportunityBookmarkItem from "./opportunity_bookmark_item";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import {
  GetCanvasAndOppBookmarks,
  ModelBookmarks,
  module_bookmarks,
  MODULE_BOOKMARKS,
  RemoveBookmarkAction,
  ChangePageAction,
  EditBookmarkNoteAction,
} from "../../../models/model_bookmarks";
import queryString from "query-string";
import { doAction, mapProp } from "module-reaction";
import _ from "lodash";
import "./bookmarks_all.less";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  })
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

@mapProp(module_bookmarks)
export class BookmarksAll extends React.Component<ModelBookmarks, {}> {
  state = {
    showDelete: false,
    deleteId: 0,
    showEdit: false,
    curNote: "",
    editId: 0,
    curPage: 1,
  };

  componentDidMount() {
    doAction(GetCanvasAndOppBookmarks);
  }

  componentWillUnmount() {
    doAction(MODULE_BOOKMARKS, module_bookmarks);
  }

  onNoteChange = (note: string) => {
    doAction(EditBookmarkNoteAction, { id: this.state.editId, note: note });
  };

  onCanvasDelete = (id: number) => {
    this.setState({
      ...this.state,
      showDelete: true,
      deleteId: id,
    });
  };

  pageOnChange = (event: unknown, page: number) => {
    doAction(ChangePageAction, page + 1);
  };

  render() {
    const { type } = queryString.parse(window.location.search);

    return (
      <div className="bookmarks-all-root">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/bookmarks">
              <span className="bread-crumb-item">Bookmarks</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="bread-crumb-item">
              {type === "canvas" ? "Saved Portfolios" : "Saved Opportunities"}
            </span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <span className="title">
          {type === "canvas" ? "Saved Portfolios" : "Saved Opportunities"}
        </span>
        <div className="list">
          {type === "canvas"
            ? _.map(this.props.canvasListByPage, (canvas, index) => {
                return (
                  <CanvasBookmarkItem
                    canvas={canvas.canvas}
                    key={index}
                    note={canvas.note}
                    history={this.props.history}
                    onNote={() => {
                      this.setState({
                        ...this.state,
                        curNote: canvas.note,
                        editId: canvas.canvas.id,
                        showEdit: true,
                      });
                    }}
                    onDelete={(id: number) => this.onCanvasDelete(id)}
                  />
                );
              })
            : _.map(this.props.opportunityListByPage, (opportunity, index) => {
                return (
                  <OpportunityBookmarkItem
                    opportunity={opportunity.job}
                    key={index}
                    history={this.props.history}
                  />
                );
              })}
        </div>
        <div
          hidden={
            type === "canvas"
              ? this.props.canvasList.length <= 6
              : this.props.opportunityList.length <= 6
          }
        >
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={
              type === "canvas"
                ? this.props.canvasList.length || 0
                : this.props.opportunityList.length || 0
            }
            rowsPerPage={6}
            page={this.props.curPage - 1}
            onPageChange={this.pageOnChange}
            ActionsComponent={TablePaginationActions}
          />
        </div>
        <ConfirmModal
          visible={this.state.showDelete}
          description="Are you sure to remove this bookmark?"
          onClose={() => this.setState({ ...this.state, showDelete: false })}
          onConfirm={() => {
            this.setState({ ...this.state, showDelete: false });
            doAction(RemoveBookmarkAction, this.state.deleteId);
          }}
        />
        {this.state.showEdit && (
          <EditNoteModal
            visible
            onClose={() => this.setState({ ...this.state, showEdit: false })}
            onEdit={(note: string) => {
              this.setState({ ...this.state, showEdit: false });
              this.onNoteChange(note);
            }}
            note={this.state.curNote}
          />
        )}
      </div>
    );
  }
}
