import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { TextField } from '@material-ui/core';
import { getInstaUserInfo, updateInstagramInfo, removeInstagramInfo } from '../../api/Insta';
import IconIns from "../../assets/images/ic_instagram.svg";

interface InstagramConnectProps {
  portfolioId: number;
}

const InstagramConnect: React.FC<InstagramConnectProps> = ({ portfolioId }) => {
  const [instagramHandle, setInstagramHandle] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newHandle, setNewHandle] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!portfolioId || portfolioId === 0) return;

    const fetchInstagramInfo = async () => {
      setLoading(true);
      try {
        const response = await getInstaUserInfo(portfolioId);
        if (response.data?.instagramHandle) {
          setInstagramHandle(response.data.instagramHandle);
        }
      } catch (error) {
        console.error('Error fetching Instagram info:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchInstagramInfo();
  }, [portfolioId]);

  const handleConnect = async () => {
    setLoading(true);
    try {
      await updateInstagramInfo(portfolioId, newHandle);
      setInstagramHandle(newHandle);
      setIsModalVisible(false);
      setNewHandle('');
    } catch (error) {
      console.error('Error updating Instagram info:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnect = async () => {
    setLoading(true);
    try {
      await removeInstagramInfo(portfolioId);
      setInstagramHandle('');
    } catch (error) {
      console.error('Error removing Instagram info:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div style={{ margin: '8px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={IconIns} alt="instagram" style={{ marginRight: '8px' }} />{instagramHandle ? (
            <div>
              <p>Connected Instagram account: @{instagramHandle}</p>
              <Button onClick={handleDisconnect}>
                Disconnect Instagram
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => setIsModalVisible(true)}
            >
              Connect to Instagram
            </Button>
          )}
        </div>

        <Modal
          title="Connect Instagram Account"
          visible={isModalVisible}
          onOk={handleConnect}
          onCancel={() => setIsModalVisible(false)}
          okText="Connect"
          confirmLoading={loading}
        >
          <TextField
            label="Instagram Handle"
            variant="filled"
            fullWidth
            value={newHandle}
            onChange={(e) => setNewHandle(e.target.value)}
            placeholder="Enter your Instagram handle"
          />
        </Modal>
      </div>
    </Spin>
  );
};

export default InstagramConnect;
