const userIdStorageKey = "canvas:local:userid";
const trackIdStorageKey = "canvas:local:trackid";
const domain = window.location.hostname === 'localhost' ? 'localhost' : '.beautista.com';

const buildTokenStorageKey = () => {
  if (window.location.hostname === 'localhost') {
    return 'canvas:token:local';
  } else if (window.location.hostname.includes('staging')) {
    return 'canvas:token:staging';
  }
  return 'canvas:token';
}

export const getToken = (): string | null => {
  const storageKey = buildTokenStorageKey();
  const tokenCookie = document.cookie.split('; ').find((row) => row.startsWith(`${storageKey}=`));
  if (tokenCookie) {
    const token = tokenCookie.split('=')[1];
    return token;
  }
};

const setSharedCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();

  document.cookie = `${name}=${value}; ${expires}; path=/; domain=${domain}; Secure; SameSite=None`;
}

const removeSharedCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}; Secure; SameSite=None`;
}

export const setToken = (token: string): void => {
  const storageKey = buildTokenStorageKey();
  setSharedCookie(storageKey, token, 30);
};

export const getUserId = () => {
  return localStorage.getItem(userIdStorageKey);
};

export const setUserId = (id: string) => {
  return localStorage.setItem(userIdStorageKey, id);
};

export const getTrackId = () => {
  return localStorage.getItem(trackIdStorageKey);
};

export const setTrackId = (id: string) => {
  return localStorage.setItem(trackIdStorageKey, id);
};

export const clearUser = () => {
  const storageKey = buildTokenStorageKey();
  removeSharedCookie(storageKey);
  localStorage.removeItem(storageKey);
  localStorage.removeItem(userIdStorageKey);
  sessionStorage.clear();
  localStorage.removeItem(trackIdStorageKey);
  localStorage.removeItem('user_id')

  const win = window as any;
  if (win && win.growsurf) {
    win.growsurf.logout();
  }
};

export const setLoginInfo = (data: any) => {
  setToken(data.token);
  setUserId(data.userId);
};
