import React from "react";
import { RouteComponentProps } from "react-router";
import "./homepage_terms.less";

export class HomepageTerms extends React.Component<RouteComponentProps> {
  render() {
    return (
      <div className="homepage-terms-wrap">
        <div className="terms-banner padding-layout">
          <div className="homepage-title-wrap">
            <div className="title">Terms of Use</div>
          </div>
        </div>
        <div className="terms-content">
          <div className="padding-layout">
            <h2>
              BY ACCESSING THIS SITE AND AGREEING TO BECOMING A MEMBER OF THE
              BEAUTISTA COMMUNITY, YOU INDICATE THAT YOU HAVE READ THE BELOW
              TERMS AND CONDITIONS, UNDERSTAND THEM, ACKNOWLEDGE THAT YOU HAVE
              OBTAINED LEGAL COUNSEL OR KNOWINGLY DECLINED TO DO SO AND AGREE TO
              BE BOUND BY THE TERMS OF AND CONDITIONS DELINEATED BELOW.
            </h2>

            <h3>Acceptance of the Terms of Use</h3>

            <h2>AGREEMENT BETWEEN YOU AND BEAUTISTA</h2>

            <p>
              This is a binding agreement ("Agreement") between Beautiful Canvas
              LLC t/a BEAUTISTA. a New Jersey Limited liability company
              ("Company") and You, the user ("You" or "Your"). BEAUTISTA’s web
              site, www.canvasME.com ("Site") are offered to You conditioned on
              Your express acceptance of the terms, conditions, and notices
              contained herein. By accessing the Site and becoming a
              member/creator of BEAUTISTA’s community, You confirm Your
              acceptance of, and agree to be bound by, this Agreement and all
              such terms, conditions and notices.
            </p>

            <p>
              You confirm the You are <b>over 18 years of age.</b> If You are
              under 18, You have Your parents express permission to do so and
              that You and Your Parents have reviewed and complied with our
              Privacy Policy and accept and agree to be bound by this Agreement
              and all such terms, conditions and notices.
            </p>

            <p>
              By completing the registration process for this Site, You
              represent and warrant that You are at least 18 years of age and
              have read, understand, have the legal capacity to, and hereby
              agree to be legally bound by these terms and conditions. If You
              are under the legal age of majority in Your state or province of
              residence (a "Minor"), represent that one of Your parents or legal
              guardians has read, understood and agreed to be legally bound by
              these Site Terms of Use, and that such agreement constitutes
              acceptance of these Site Terms of Use on behalf of You and
              themselves.
            </p>

            <p>
              This document, together with any documents it expressly
              incorporates by reference (collectively, "Terms of Use"), governs
              your access to and use of www.canvasme.com, including any content,
              functionality, and services offered on or through www.canvasme.com
              (the "Website"), whether as a guest or a registered user.
            </p>

            <p>
              Please read the Terms of Use carefully before you use the Website.
              By clicking the check box you accept and agree to be bound and
              abide by these Terms of Use and our Privacy Policy, found at{" "}
              <a href="/privacy-policy">www.canvasme.com/privacy-policy</a>,
              incorporated herein by reference.{" "}
              <b>
                If you do not agree to these Terms of Use and the Privacy
                Policy, you must not access or use the Website.
              </b>
            </p>

            <p>
              This Website is offered and available only to users who are 18
              years of age or older and reside in the United States or any of
              its territories or possessions. By using this Website, you
              represent and warrant that you are of legal age to form a binding
              contract with the Company and meet all of the foregoing
              eligibility requirements. If you do not meet all of these
              requirements, you must not access or use the Website.
            </p>

            <p>
              This Website is not intended for children under the age of 18 and
              we do not knowingly allow access by or collect information from
              children, especially under the age of 13. If we become aware that
              a user is under the age of 13 and has submitted any information to
              us for any purpose, we will delete that information from our
              files. In addition, if a parent or guardian becomes aware that a
              minor has submitted personal information, that parent or guardian
              should immediately tell us that they would like this information
              deleted from our records by contacting us using the contact form
              found <a href="/privacy-rights">here</a>.
            </p>

            <p>
              If you are under 18, you should review these terms and conditions
              with your parent or guardian to make sure that you and your parent
              or guardian understand and consents to these terms and conditions.
              Further, you represent and warrant that you (a) have not
              previously been suspended by us or removed from the Website; (b)
              do not have more than one account for the Website at any given
              time; and (c) that you have full power and authority to enter into
              these Terms and in doing so will not violate any other agreement
              to which you are a party.
            </p>

            <h3>Definitions</h3>
            <p>
              a. A "user" is anyone who accesses, browses, crawls, scrapes, or
              in any way uses the Website. The terms "you" and "your" refer to
              you, as a user of the Website, and anyone with whom you share
              access or otherwise authorize to use your account.
            </p>
            <p>
              b. "Content" means text, images, photos, audio, video, and all
              other forms of data or communication. "Your Content" means Content
              that you submit or transmit to or through the Website, such as
              location information and any message you may post on or through
              the Website or information that you display as part of your
              account profile. "User Content" means Content that a user submits
              or transmits to or through the Site, including Your Content. "Our
              Content" means Content that we create and/or otherwise make
              available on the Site. "Third Party Content" means Content that is
              made available on the Website by parties other than us or our
              users, such as data providers who license data to us for use on
              the Website.
            </p>

            <h3>Changes to the Terms of Use</h3>
            <p>
              We may revise and update these Terms of Use from time to time in
              our sole discretion. All changes are effective immediately when we
              post them, and apply to all access to and use of the Website
              thereafter. However, any changes to the dispute resolution
              provisions set out in Governing Law and Jurisdiction will not
              apply to any disputes for which the parties have actual notice on
              or before the date the change is posted on the Website.
            </p>
            <p>
              Your continued use of the Website following the posting of revised
              Terms of Use means that you accept and agree to the changes. You
              are expected to check this page each time you access this Website
              so you are aware of any changes, as they are binding on you.
            </p>

            <h3>Accessing the Website and Account Security</h3>
            <p>
              We reserve the right to amend or withdraw all of any of this
              portion of this Website, and any service or material we provide on
              the Website, in our sole discretion without prior notice. We will
              not be liable if for any reason all or any part of or the entire
              Website is unavailable at any time or for any period. From time to
              time, we may restrict access to some parts of the Website, or the
              entire Website, to users, including registered users.
            </p>

            <p>
              You are responsible for both:
              <br />
              • Making all arrangements necessary for you to have access to the
              Website.
              <br />• Ensuring that all persons who access the Website through
              your internet connection are aware of these Terms of Use and
              comply with them.
            </p>
            <p>
              To access the Website or some of the resources it offers, you may
              be asked to provide certain registration details or other
              information. It is a condition of your use of the Website that all
              the information you provide on the Website is correct, current,
              and complete. You agree that all information you provide to
              register with this Website or otherwise, including, but not
              limited to, through the use of any interactive features on the
              Website, is governed by our Privacy Policy, and you consent to all
              actions we take with respect to your information consistent with
              our Privacy Policy.
            </p>
            <p>
              If you choose, or are provided with, a user name, password, or any
              other piece of information as part of our security procedures, you
              shall treat such information as confidential, and will not
              disclose it to any other person or entity. Your account is
              personal to you and agree not to provide any other person with
              access to this Website or portions of it using your user name,
              password, or other security information. You agree to notify us
              immediately of any unauthorized access to or use of your user name
              or password or any other breach of security. You also agree to
              ensure that you exit from your account at the end of each session.
              You should use particular caution when accessing your account from
              a public or shared computer so that others are not able to view or
              record your password or other personal information.
            </p>
            <p>
              We have the right to disable any user’s access to the Website, as
              well as any user name, password, or other identifier, whether
              chosen by you or provided by us, at any time in our sole
              discretion for any or no reason, including if, in our opinion, you
              have violated any provision of these Terms of Use or the Privacy
              Policy.
            </p>

            <h3>Membership and Submissions</h3>
            <p>
              When Membership is launched, Membership privileges are provided to
              individuals and to the registered subscriber member. Membership
              privileges cannot be distributed, accessed, or in any other way
              transferred to anyone other than the registered Member. By
              completing the registration procedure, you become a "Member," and
              you declare that the data you supply is true, accurate, and
              current.
            </p>
            <p>
              Each Member is required to have a working email account and
              password for system access. Members are not allowed to divulge
              their login credentials to outside parties. Any Member who
              violates the Terms and Conditions as posted on the website or
              within Mobile Application, or who abuses its rights in relation to
              the Service may be refused service, and we reserve the right to do
              so. This also applies to any person connected to or affiliated
              with any such Member, person, organization, or firm (and all other
              individuals).{" "}
            </p>
            <p>
              You are aware that by using the Services, you may encounter other
              users' User Materials, and you agree that some of these User
              Materials may be unreliable, disagreeable, offensive, or indecent.
              In connection to User Materials, you consent to giving up any
              legal or equitable claim you might have against us.{" "}
            </p>

            <h3>Site Usage</h3>

            <p>
              As a condition of Your use of the Site, You agree that You will
              not:
              <br />
              • Violate any law, contract or intellectual property (copyright,
              trademark, etc.) right, or commit any civil wrong against any
              individual or company, including Company, Inc. (libel, slander,
              negligence, etc. (tort));
              <br />
              • Attempt to access any service or area of the Site that You are
              not authorized to access;
              <br />
              • Alter information on or obtained from the Site including any of
              Company’s Client’s assets (logos, etc.);
              <br />
              • Use any robot, spider, scraper or other automated means or
              interface not provided by us to access the Site or to extract
              data;
              <br />
              • Reverse engineer any aspect of the Site or do anything that
              might discover source code, or bypass or circumvent measures
              employed to prevent or limit access to any area, content or code
              of the Site (except as otherwise expressly permitted by law);
              <br />
              • Send to or otherwise impact Company or the Site (or anything or
              anyone else) with harmful, illegal, deceptive or disruptive code
              such as a virus, "spyware", "adware" or other code that could
              adversely impact the Site or any recipient;
              <br />
              • Access or use the Site or any service for any unlawful,
              unintended (by Company) or harmful purpose, or other than in full
              compliance with applicable law and this Agreement;
              <br />
              • Take any action which might impose a significant burden (as
              determined by Company) on the Site infrastructure;
              <br />
              • Interfere with the ordinary operation or mission of the Site or
              services;
              <br />
              • Permit anyone other than Yourself and Your personnel to use Your
              User Information to access any portions of the Site which are
              restricted; and "Frame" the Site or otherwise make it look like
              You have a relationship to Company or that we have endorsed You
              for any purpose.
              <br />• You further agree that (1) any information You provide in
              connection with Your use of the Site is true, accurate and
              complete and You will maintain and update such information
              regularly; and (2) You will respect the intellectual property and
              other informational and all rights of BEAUTISTA and others.
            </p>

            <h3>Intellectual Property Rights</h3>
            <p>
              The Website and all of its content, features, and functionality
              (including but not limited to all information, software, text,
              displays, images, video, and audio, and the design, selection, and
              arrangement thereof) are owned or licensed by the Company and are
              protected by United States and international copyright, trademark,
              patent, trade secret, and other intellectual property or
              proprietary rights laws.
            </p>
            <p>
              These Terms of Use permit you to use the Website for your
              personal, non-commercial use only. You may not scrub data from,
              frame, reproduce, distribute, modify, create derivative works of,
              publicly display, publicly perform, republish, download, store, or
              transmit any of the material on our Website, except as follows:
              <br />
              • Your computer may temporarily store copies of such materials in
              RAM incidental to your accessing and viewing those materials.
              <br />
              • You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
              <br />
              • You may print or download one copy of a reasonable number of
              pages of the Website for your own personal, non-commercial use and
              not for further reproduction, publication, or distribution.
              <br />
              • If we provide desktop, mobile, or other applications for
              download, you may download a single copy to your computer or
              mobile device solely for your own personal, non-commercial use,
              provided you agree to be bound by our end user license agreement
              for such applications.
              <br />• If we provide social media features with certain content,
              you may take such actions as are enabled by such features.
            </p>
            <p>
              You may not:
              <br />
              • Modify copies of any materials from the Website <br />
              • Use any illustrations, photographs, video or audio sequences, or
              any graphics separately from the accompanying text.
              <br />
              • Delete or alter any copyright, trademark, or other proprietary
              rights notices from copies of materials from this Website.
              <br />
            </p>
            <p>
              You may not access or use for any commercial purposes any part of
              the Website or any services or materials available through the
              Website.
            </p>
            <p>
              If you wish to request the right or license to make any use of
              material on the Website other than that as set out in this
              section, please address your request to:hello@beautista.com. We
              will grant or reject all such requests in our sole discretion.
            </p>
            <p>
              If you print, copy, modify, download, or otherwise use or provide
              any other person with access to any part of the Website in breach
              of the Terms of Use, your right to use the Website will stop
              immediately and you must, at our option, return or destroy any
              copies of the materials you have made. No right, title, or
              interest in or to the Website or any content on the Website is
              transferred to you, and all rights not expressly granted are
              reserved by the Company. Any use of the Website not expressly
              permitted by these Terms of Use is a breach of these Terms of Use
              and may violate copyright, trademark, and other laws.
            </p>

            <h3>Trademarks</h3>
            <p>
              The Company name, Beautiful Canvas LLC d/b/a BEAUTISTA, all
              Company logos, and all related names, logos, product and service
              names, designs, and slogans are registered and unregistered
              trademarks of the Company or its affiliates or licensors. You may
              not use such marks without the prior written permission of the
              Company. All other names, logos, product and service names,
              designs, and slogans on this Website are the trademarks of their
              respective owners.
            </p>

            <h3>Prohibited Uses</h3>
            <p>
              You may use the Website only for lawful purposes and in accordance
              with these Terms of Use. You agree not to use the Website:
              <br />
              • In any way that violates any applicable federal, state, local,
              or international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).
              <br />
              • For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information, or
              otherwise.
              <br />
              • To send, knowingly receive, upload, download, use, or re-use any
              material that does not comply with the Content Standards set out
              in these Terms of Use.
              <br />
              • To transmit, or procure the sending of, any advertising or
              promotional material without our prior written consent, including
              any "junk mail," "chain letter," "spam," or any other similar
              solicitation.
              <br />
              • To impersonate or attempt to impersonate the Company, a Company
              employee, another user, or any other person or entity (including,
              without limitation, by using email addresses or screen names
              associated with any of the foregoing).
              <br />• To engage in any other conduct that restricts or inhibits
              anyone's use or enjoyment of the Website, or which, as determined
              by us, may harm the Company or users of the Website, or expose
              them to liability.
            </p>
            <p>
              Additionally, you agree not to:
              <br />
              • Use the Website in any manner that could disable, overburden,
              damage, or impair the site or interfere with any other party's use
              of the Website, including their ability to engage in real time
              activities through the Website.
              <br />
              • Use any robot, spider, or other automatic device, process, or
              means to access the Website for any purpose, including monitoring
              or copying any of the material on the Website.
              <br />
              • Use any manual process to monitor or copy any of the material on
              the Website, or for any other purpose not expressly authorized in
              these Terms of Use, without our prior written consent.
              <br />
              • Use any device, software, or routine that interferes with the
              proper working of the Website.
              <br />
              • Introduce any viruses, Trojan horses, worms, logic bombs, or
              other material that is malicious or technologically harmful.
              <br />
              • Attempt to gain unauthorized access to, interfere with, damage,
              or disrupt any parts of the Website, the server on which the
              Website is stored, or any server, computer, or database connected
              to the Website.
              <br />
              • Attack the Website via a denial-of-service attack or a
              distributed denial-of-service attack.
              <br />• Otherwise attempt to interfere with the proper working of
              the Website.
            </p>

            <h3>User Contributions</h3>
            <p>
              The Website may contain personal profiles or web pages, forums,
              personal portfolios, message boards, chat rooms, forums, bulletin
              boards, and other interactive features (collectively, "Interactive
              Services") that allow users to post, submit, publish, display, or
              transmit to other users or other persons (hereinafter, "post")
              content or materials (collectively, "User Contributions") on or
              through the Website.
            </p>
            <p>
              All User Contributions must comply with the Content Standards set
              out in these Terms of Use.
            </p>
            <p>
              Any User Contribution you post to the site will be considered
              non-confidential and non-proprietary. By providing any User
              Contribution on the Website, you grant us and our affiliates and
              service providers, and each of their and our respective licensees,
              successors, and assigns the right to use, reproduce, modify,
              perform, display, distribute, and otherwise disclose to third
              parties any such material for any purpose.
            </p>
            <p>
              You represent and warrant that:
              <br />
              • You own or control all rights in and to the User Contributions
              and have the right to grant the license granted above to us and
              our affiliates and service providers, and each of their and our
              respective licensees, successors, and assigns.
              <br />• All of your User Contributions do and will comply with
              these Terms of Use.
            </p>
            <p>
              You understand and acknowledge that you are responsible for any
              User Contributions you submit or contribute, and you, not the
              Company, have full responsibility for such content, including its
              legality, reliability, accuracy, and appropriateness.
            </p>
            <p>
              We are not responsible or liable to any third party for the
              content or accuracy of any User Contributions posted by you or any
              other user of the Website.
            </p>

            <p>
              You grant Company, its affiliated entities and sub-licensees a
              non-exclusive, perpetual, worldwide, royalty free, fully paid up
              and irrevocable license to use Your materials or information,
              including without limitation the rights to copy, distribute,
              transmit, publicly display, publicly perform, reproduce, edit,
              make derivative or collective works, translate and reformat the
              materials or information, and to publish Your name and image in
              connection with Your materials or information and Your profile
              page. Unless otherwise agreed in accordance with a particular
              assignment, Company will not pay You compensation with respect to
              any use of Your materials or information.
            </p>

            <h3>Monitoring and Enforcement; Termination</h3>
            <p>
              We have the right to:
              <br />
              • Remove or refuse to post any User Contributions for any or no
              reason in our sole discretion.
              <br />
              • Take any action with respect to any User Contribution that we
              deem necessary or appropriate in our sole discretion, including if
              we believe that such User Contribution violates the Terms of Use,
              including the Content Standards, infringes any intellectual
              property right or other right of any person or entity, threatens
              the personal safety of users of the Website or the public, or
              could create liability for the Company.
              <br />
              • Disclose your identity or other information about you to any
              third party who claims that material posted by you violates their
              rights, including their intellectual property rights or their
              right to privacy.
              <br />
              • Take appropriate legal action, including without limitation,
              referral to law enforcement, for any illegal or unauthorized use
              of the Website.
              <br />• Terminate or suspend your access to all or part of the
              Website for any or no reason, including without limitation, any
              violation of these Terms of Use.
            </p>
            <p>
              Without limiting the foregoing, we have the right to cooperate
              fully with any law enforcement authorities or court order
              requesting or directing us to disclose the identity or other
              information of anyone posting any materials on or through the
              Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS
              AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS
              RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES
              DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER
              SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
            </p>
            <p>
              However, we cannot and do not undertake to review all material
              before it is posted on the Website, and cannot ensure prompt
              removal of objectionable material after it has been posted.
              Accordingly, we assume no liability for any action or inaction
              regarding transmissions, communications, or content provided by
              any user or third party. We have no liability or responsibility to
              anyone for performance or nonperformance of the activities
              described in this section.
            </p>

            <h3>Content Standards</h3>
            <p>
              These content standards apply to any and all User Contributions
              and use of Interactive Services. User Contributions must in their
              entirety comply with all applicable federal, state, local, and
              international laws and regulations. Without limiting the
              foregoing, User Contributions must not:
              <br />
              • Contain any material that is defamatory, obscene, indecent,
              abusive, offensive, harassing, violent, hateful, inflammatory, or
              otherwise objectionable.
              <br />
              • Promote sexually explicit or pornographic material, violence, or
              discrimination based on race, sex, religion, nationality,
              disability, sexual orientation, or age.
              <br />
              • Infringe any patent, trademark, trade secret, copyright, or
              other intellectual property or other rights of any other person.
              <br />
              • Violate the legal rights (including the rights of publicity and
              privacy) of others or contain any material that could give rise to
              any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with these Terms
              of Use and our Privacy Policy.
              <br />
              • Be likely to deceive any person.
              <br />
              • Promote any illegal activity, or advocate, promote, or assist
              any unlawful act.
              <br />
              • Cause annoyance, inconvenience, or needless anxiety or be likely
              to upset, embarrass, alarm, or annoy any other person.
              <br />
              • Impersonate any person, or misrepresent your identity or
              affiliation with any person or organization.
              <br />
              • Involve commercial activities or sales, such as contests,
              sweepstakes, and other sales promotions, barter, or advertising.
              <br />• Give the impression that they emanate from or are endorsed
              by us or any other person or entity, if this is not the case.
            </p>

            <h3>Copyright Infringement</h3>
            <p>
              We take claims of copyright infringement seriously. We will
              respond to notices of alleged copyright infringement that comply
              with applicable law. If you believe any materials accessible on or
              from this site (the "Website") infringe your copyright, you may
              request removal of those materials (or access to them) from the
              Website by submitting written notification to our copyright agent
              designated below. In accordance with the Online Copyright
              Infringement Liability Limitation Act of the Digital Millennium
              Copyright Act (17 U.S.C. § 512) ("DMCA"), the written notice (the
              "DMCA Notice") must include substantially the following:
              <br />
              • Your physical or electronic signature.
              <br />
              • Identification of the copyrighted work you believe to have been
              infringed or, if the claim involves multiple works on the Website,
              a representative list of such works.
              <br />
              • Identification of the material you believe to be infringing in a
              sufficiently precise manner to allow us to locate that material.
              <br />
              • Adequate information by which we can contact you (including your
              name, postal address, telephone number, and, if available, email
              address).
              <br />
              • A statement that you have a good faith belief that use of the
              copyrighted material is not authorized by the copyright owner, its
              agent, or the law.
              <br />
              • A statement that the information in the written notice is
              accurate.
              <br />• A statement, under penalty of perjury, that you are
              authorized to act on behalf of the copyright owner.
            </p>
            <p>Our designated copyright agent to receive DMCA Notices is:</p>
            <p>
              Jonathan Lorie
              <br />
              Duane Morris LLP
              <br />
              100 High Street, Suite 2400
              <br />
              Boston, MA 02110-1724
              <br />
              JLourie@duanemorris.com
            </p>
            <p>
              If you fail to comply with all of the requirements of Section
              512(c)(3) of the DMCA, your DMCA Notice may not be effective.
            </p>
            <p>
              Please be aware that if you knowingly materially misrepresent that
              material or activity on the Website is infringing your copyright,
              you may be held liable for damages (including costs and attorneys'
              fees) under Section 512(f) of the DMCA.
            </p>
            <p>
              Counter Notification Procedures
              <br />
              If you believe that material you posted on the Website was removed
              or access to it was disabled by mistake or misidentification, you
              may file a counter notification with us (a "Counter Notice") by
              submitting written notification to our copyright agent designated
              above. Pursuant to the DMCA, the Counter Notice must include
              substantially the following:
              <br />
              • Your physical or electronic signature.
              <br />
              • An identification of the material that has been removed or to
              which access has been disabled and the location at which the
              material appeared before it was removed or access disabled.
              <br />
              • Adequate information by which we can contact you (including your
              name, postal address, telephone number, and, if available, email
              address).
              <br />
              • A statement under penalty of perjury by you that you have a good
              faith belief that the material identified above was removed or
              disabled as a result of a mistake or misidentification of the
              material to be removed or disabled.
              <br />• A statement that you will consent to the jurisdiction of
              the Federal District Court for the judicial district in which your
              address is located (or if you reside outside the United States for
              any judicial district in which the Website may be found) and that
              you will accept service from the person (or an agent of that
              person) who provided the Website with the complaint at issue.
            </p>
            <p>
              The DMCA allows us to restore the removed content if the party
              filing the original DMCA Notice does not file a court action
              against you within ten business days of receiving the copy of your
              Counter Notice.
            </p>
            <p>
              Please be aware that if you knowingly materially misrepresent that
              material or activity on the Website was removed or disabled by
              mistake or misidentification, you may be held liable for damages
              (including costs and attorneys' fees) under Section 512(f) of the
              DMCA.
            </p>

            <h3>Reliance on Information Posted</h3>
            <p>
              The information presented on or through the Website is made
              available solely for general information purposes. We do not
              warrant the accuracy, completeness, or usefulness of this
              information. Any reliance you place on such information is
              strictly at your own risk. We disclaim all liability and
              responsibility arising from any reliance placed on such materials
              by you or any other visitor to the Website, or by anyone who may
              be informed of any of its contents.
            </p>
            <p>
              This Website includes content provided by third parties, including
              materials provided by other users, bloggers, and third-party
              licensors, syndicators, aggregators, and/or reporting services.
              All statements and/or opinions expressed in these materials, and
              all articles and responses to questions and other content, other
              than the content provided by the Company, are solely the opinions
              and the responsibility of the person or entity providing those
              materials. These materials do not necessarily reflect the opinion
              of the Company. We are not responsible, or liable to you or any
              third party, for the content or accuracy of any materials provided
              by any third parties.
            </p>

            <h3>Changes to the Website</h3>
            <p>
              We may update the content on this Website from time to time, but
              its content is not necessarily complete or up-to-date. Any of the
              material on the Website may be out of date at any given time, and
              we are under no obligation to update such material.
            </p>

            <h3>Information About You and Your Visits to the Website</h3>
            <p>
              All information we collect about you on this Website is subject to
              our Privacy Policy.
            </p>

            <h3>Fees, Online Purchases and Other Terms and Conditions</h3>
            <p>
              You may be required to pay fees to access certain features of the
              Website or obtain certain goods or services. All fees are in U.S.
              dollars and are non-refundable. If Company changes the fees for
              all or part of the Website or for any goods or services, including
              by adding fees or charges, Company will provide you advance notice
              of those changes. If you do not accept the changes, Company may
              discontinue providing the applicable part of the Website to you.
              Company’s authorized third-party payment processor will charge the
              payment method you specified at the time of purchase. You
              authorize Company to charge all fees for the Website you select to
              that payment method. If you pay any fees with a credit card,
              Company may seek pre-authorization of your credit card account
              before your purchase to verify that the credit card is valid and
              has the necessary funds or credit available to cover your
              purchase.
            </p>
            <p>
              Furthermore, all purchases through our site or other transactions
              for the sale of goods, services, or information formed through the
              Website, or resulting from visits made by you, may be governed by
              additional terms presented at or around the time of sale, which
              are hereby incorporated into these Terms of Use. If any such
              purchases are with any third parties, Company is an information
              provider and does not endorse or recommend any services providers.
              Company makes no representation or warranty as to the
              qualifications or competency of any services providers or as to
              the quality or completeness of any such services providers’ work.
              Company has no responsibility to review or validate any sample
              materials provided by such services providers for display on the
              Website.
            </p>
            <p>
              Additional terms and conditions may also apply to specific
              portions, services, or features of the Website. All such
              additional terms and conditions are hereby incorporated by this
              reference into these Terms of Use.
            </p>

            <h3>Linking to the Website and Social Media Features</h3>
            <p>
              You may link to our homepage, provided you do so in a way that is
              fair and legal and does not damage our reputation or take
              advantage of it, but you must not establish a link in such a way
              as to suggest any form of association, approval, or endorsement on
              our part without our express written consent.
            </p>
            <p>
              This Website may provide certain social media features that enable
              you to:
              <br />
              • Link from your own or certain third-party websites to certain
              content on this Website.
              <br />
              • Send emails or other communications with certain content, or
              links to certain content, on this Website.
              <br />• Cause limited portions of content on this Website to be
              displayed or appear to be displayed on your own or certain
              third-party websites.
            </p>
            <p>
              You may use these features solely as they are provided by us and
              solely with respect to the content they are displayed with and
              otherwise in accordance with any additional terms and conditions
              we provide with respect to such features. Subject to the
              foregoing, you must not:
              <br />
              • Establish a link from any website that is not owned by you.
              <br />
              • Cause the Website or portions of it to be displayed on, or
              appear to be displayed by, any other site, for example, framing,
              deep linking, or in-line linking.
              <br />
              • Link to any part of the Website other than the homepage.
              <br />• Otherwise take any action with respect to the materials on
              this Website that is inconsistent with any other provision of
              these Terms of Use.
            </p>
            <p>
              You agree to cooperate with us in causing any unauthorized framing
              or linking immediately to stop. We reserve the right to withdraw
              linking permission without notice.
            </p>
            <p>
              We may disable all or any social media features and any links at
              any time without notice in our discretion.
            </p>

            <h3>Links from the Website</h3>
            <p>
              If the Website contains links to other sites and resources
              provided by third parties including links to participating
              independent contractors that have agreed to list their services,
              these links are provided for your convenience only. This includes
              links contained in advertisements, including banner advertisements
              and sponsored links. We have no control over the contents of those
              sites or resources, and accept no responsibility for them or for
              any loss or damage that may arise from your use of them. If you
              decide to access any of the third-party websites linked to this
              Website, you do so entirely at your own risk and subject to the
              terms and conditions of use for such websites.
            </p>

            <h3>Geographic Restrictions</h3>
            <p>
              The owner of the Website is based in the State of New Jersey in
              the United States. We provide this Website for use only by persons
              located in the United States. We make no claims that the Website
              or any of its content is accessible or appropriate outside of the
              United States. Access to the Website may not be legal by certain
              persons or in certain countries. If you access the Website from
              outside the United States, you do so on your own initiative and
              are responsible for compliance with local laws.
            </p>

            <h3>Third Party Services and/or Products</h3>
            <p>
              We may make available links to third party websites as well as
              third party products and services for purchase through the
              Website. Descriptions and images of, and references to, third
              party products or services available in connection with the
              Website do not imply our endorsement of such third party products
              or services. All descriptions, images, references, features,
              content, specifications, products and prices of products and
              services described or depicted on the Website are subject to
              change at any time without notice. Certain weights, measures and
              similar descriptions are approximate and are provided for
              convenience purposes only. The inclusion of any products or
              services on the Website at a particular time does not imply or
              warrant that these products or services will be available at any
              time. It is your responsibility to ascertain and obey all
              applicable local, state, federal and international laws (including
              minimum age requirements) in regard to the possession, use and
              sale of any item purchased from this Website. By placing an order,
              you represent that the products ordered will be used only in a
              lawful manner. We reserve the right, with or without prior notice,
              to limit the available quantity of or discontinue any product or
              service, to impose conditions on the honoring of, any coupon,
              coupon code, promotional code or other similar promotions, to bar
              you from making purchase(s), and/or to refuse to provide you with
              any product or service. You agree to pay all charges that may be
              incurred by you or on your behalf through the Website, at the
              price(s) in effect when such charges are incurred, including
              without limitation all shipping and handling charges. In addition,
              you remain responsible for any taxes that may be applicable to
              your purchases. The risk of loss and title to products purchased
              on the Website pass to the purchaser upon delivery to the carrier.
            </p>

            <h3>Disclaimer of Warranties</h3>
            <p>
              We do not and cannot represent, guarantee or warrant that the
              Website or any files available for downloading from the Website
              will be free of viruses or other destructive code. You are
              responsible for implementing sufficient procedures and checkpoints
              to satisfy your particular requirements for anti-virus protection
              and accuracy of data input and output, and for maintaining a means
              external to our site for any reconstruction of any lost data. TO
              THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
              LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
              VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT
              YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
              PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
              DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED
              TO IT.
            </p>
            <p>
              YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
              OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
              CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
              ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE
              COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY
              WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
              SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
              WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
              ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
              WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH
              THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
              UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR
              THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
              OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR
              EXPECTATIONS.
            </p>
            <p>
              TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY
              DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
              STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
              WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
              PARTICULAR PURPOSE.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>

            <h3>Limitation on Liability</h3>
            <p>
              TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE
              COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS,
              EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF
              ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION
              WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES
              LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES,
              INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
              CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
              PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
              REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
              LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
              TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE,
              EVEN IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY
              THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>

            <h3>Indemnification</h3>
            <p>
              You agree to defend, indemnify, and hold harmless the Company, its
              affiliates, licensors, and service providers, and its and their
              respective officers, directors, employees, contractors, agents,
              licensors, suppliers, successors, and assigns from and against any
              claims, liabilities, damages, judgments, awards, losses, costs,
              expenses, or fees (including reasonable attorneys' fees) arising
              out of or relating to your violation of these Terms of Use or your
              use of the Website, including, but not limited to, your User
              Contributions, any use of the Website's content, services, and
              products other than as expressly authorized in these Terms of Use,
              or your use of any information obtained from the Website.
            </p>

            <h3>Governing Law and Jurisdiction</h3>
            <p>
              All matters relating to the Website and these Terms of Use, and
              any dispute or claim arising therefrom or related thereto (in each
              case, including non-contractual disputes or claims), shall be
              governed by and construed in accordance with the internal laws of
              the State of New York without giving effect to any choice or
              conflict of law provision or rule (whether of the State of New
              Jersey or any other jurisdiction).
            </p>
            <p>
              Any legal suit, action, or proceeding arising out of, or related
              to, these Terms of Use or the Website shall be instituted
              exclusively in the federal courts of the United States or the
              courts of the State of New Jersey, in each case located in the
              City of Newark, although we retain the right to bring any suit,
              action, or proceeding against you for breach of these Terms of Use
              in your country of residence or any other relevant country. You
              waive any and all objections to the exercise of jurisdiction over
              you by such courts and to venue in such courts.
            </p>

            <p>
              <b>
                <i>NOTICE TO NEW JERSEY USERS:</i>
              </b>{" "}
              If you live in New Jersey, your agreement to this provision
              shortens the time within which you may bring a Dispute.
            </p>
            <p>
              The terms of this Dispute Resolution section survive any
              termination of these Terms.{" "}
            </p>
            <p>
              Right to opt-out or reject future changes to dispute resolution
              section{" "}
            </p>
            <p>
              You may elect to opt-out (exclude yourself) from the final,
              binding, individual arbitration procedure or waiver of class and
              representative proceedings specified in this section by sending a
              written letter via nationally recognized overnight courier to the
              attention “Customer Relations Director” within thirty (30) days of
              your first visit to and use of the Website or Mobile
              Application/app that specifies your (i) name; (ii) mailing
              address; and (iii) request to be excluded from the final, binding,
              individual arbitration procedure or waiver of class and
              representative proceedings specified in this section. In the event
              you opt-out consistent with the procedure set forth above, all
              other terms and conditions of these Terms will continue to apply.
              If you do not so opt-out, then the terms of this section will
              apply.{" "}
            </p>
            <p>
              Notwithstanding the provisions of “Changes to Terms of Use”
              section above, if BEAUTISTA changes this "Dispute Resolution"
              section after the date you first accepted these Terms (or accepted
              subsequent changes to the Terms), you may reject any such change
              by sending BEAUTISTA written notice to the attention “Customer
              Relations Director” within thirty (30) days of the date such
              change became effective, as indicated in the "Last Updated" date
              above. By rejecting any change, you are agreeing that you will
              arbitrate any Dispute between you and BEAUTISTA in accordance with
              the provisions of this "Dispute Resolution" section as of the date
              you first accepted these Terms (or accepted any subsequent changes
              to these Terms).{" "}
            </p>
            <h3>Limitation on Time to File Claims</h3>
            <p>
              ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
              RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED
              WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE,
              SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
            </p>

            <h3>Waiver and Severability</h3>
            <p>
              No waiver by the Company of any term or condition set out in these
              Terms of Use shall be deemed a further or continuing waiver of
              such term or condition or a waiver of any other term or condition,
              and any failure of the Company to assert a right or provision
              under these Terms of Use shall not constitute a waiver of such
              right or provision.
            </p>
            <p>
              If any provision of these Terms of Use is held by a court or other
              tribunal of competent jurisdiction to be invalid, illegal, or
              unenforceable for any reason, such provision shall be eliminated
              or limited to the minimum extent such that the remaining
              provisions of the Terms of Use will continue in full force and
              effect.
            </p>

            <h3>Entire Agreement</h3>
            <p>
              The Terms of Use, our Privacy Policy, and any other terms
              incorporated herein by reference constitute the sole and entire
              agreement between you and Beautiful Canvas, LLC regarding the
              Website and supersede all prior and contemporaneous
              understandings, agreements, representations, and warranties, both
              written and oral, regarding the Website.
            </p>

            <p>Important Information for California</p>
            <blockquote>
              <p>
                Pursuant to California Civil Code Section 1879.3, California
                residents are entitled to the following specific consumer rights
                notice:
              </p>
              <p>
                The name, address, and telephone number of the provider of this
                service 850 Burton Road., Ste. 201, Dover, DE 19904. Complaints
                regarding products, or requests to receive further information
                regarding use of this Website or Mobile Application/app may be
                sent to the above address or to hello@beautista.com
              </p>
              <p>
                The Complaint Assistance Unit of the Division of Consumer
                Services of the California Department of Consumer Affairs may be
                contacted in writing at 1625 North Market Boulevard, Suite N
                112, Sacramento, California 95834 or by telephone at (800)
                952-5210.
              </p>
            </blockquote>

            <p>Important Information for New Jersey residents</p>
            <blockquote>
              <p>
                If you live in New Jersey, please carefully read these Terms and
                understand that by using the Website or Mobile Application,
                placing an order on the Website or Mobile Application or
                creating an account, in addition to the other terms and
                conditions herein, you agree:
              </p>
              <p>
                Any dispute between you and will be governed by the laws of the
                State of and subject to the dispute resolution provisions set
                forth in the “Disputes Resolution” section below;
              </p>
            </blockquote>
            <p>
              Under the laws of the State of New Jersey, limitations on implied
              warranties or the exclusive limitation of certain damages as set
              forth below in the “DMCA/Copyright Policy,” “Disclaimers of
              Warranties,” “Limitations on Liability,” and “Anti-Diversion”
              sections may not apply to you; and As set forth in the
              “Indemnification” section below, you are responsible for any harm
              you cause BEAUTISTA . whether it is because of your (i) User
              Content; (ii) violation of any applicable law that protects Canvas
              ME . or our legal rights or those of any third party that your
              actions have damaged; (iii) material uncured breaches of these
              Terms; (iv) infringement by you of any intellectual property
              rights that belong to BEAUTISTA . or any third party; (v) any and
              all activities that occur under your account, username, and
              password.
            </p>

            <h3>Communications & Text Messages</h3>
            <p>
              When you use the Services, or send emails, text messages, and
              other communications from your desktop or mobile device to us, you
              may be communicating with us electronically. You consent to
              receive communications from us or on our behalf electronically,
              such as e-mails, texts, mobile push notices, or notices and
              messages through the Services, and you agree that all agreements,
              notices, disclosures, and other communications that we provide to
              you electronically satisfy any legal requirement that such
              communications be in writing. You agree to keep your contact
              information, including email address, current. This subparagraph
              does not affect your statutory rights.
            </p>
            <p>
              Your voluntary provision to BEAUTISTA of your cell phone number
              represents your consent that BEAUTISTA may contact you by
              telephone, SMS, or MMS messages at that phone number, and your
              consent to receiving such communications for transactional,
              operational, or informational purposes. When you provide your
              phone number to BEAUTISTA, you warrant that you are the current
              subscriber or authorized user of the relevant account. You
              understand and agree that such messages may be sent using
              automated technology. You may unsubscribe from receiving text
              messages from BEAUTISTA at any time. To revoke your consent to
              receiving SMS or MMS messages from BEAUTISTA, you agree to follow
              the unsubscribe procedures described below.
            </p>
            <p>
              When placing orders through the Services, you may receive order
              status messages from BEAUTISTA about each order. To unsubscribe
              from order-related messages, just reply "STOP" to the number
              sending the message. To resubscribe, text “START” to the same
              number from the phone number associated with your account. If you
              need assistance, text “HELP”. Please note that unsubscribing from
              one of the branded properties in our Services will not
              automatically unsubscribe you from another separately branded
              property in our Services. For example, if you unsubscribe from
              BEAUTISTA order-related messages, you may still receive
              order-related messages when you place an order through Seamless,
              unless you also unsubscribe from Seamless order-related messages.
            </p>
            <p>
              Additionally, you may receive messages from BEAUTISTA following
              receipt of a completed order soliciting feedback and/or other
              information relating to the order. You may unsubscribe from all
              such feedback messages by replying "STOP" to the number sending
              the feedback messages. To resubscribe, text "START" to the number
              sending the feedback messages using the phone number associated
              with your account. Please note that unsubscribing from such
              feedback texts will not prevent you from receiving texts from
              BEAUTISTA, the Merchant, or your delivery person regarding your
              order or its delivery unless you also text "STOP" to the number
              sending the order-related messages, and even in such event, you
              may still receive individual texts from the Merchant or your
              delivery person to enable successful delivery of your order.
            </p>
            <p>
              You may also receive text messages in response to certain Customer
              Care requests. To unsubscribe from Customer Care messages, just
              reply to the message by texting “STOP” to the number sending the
              message.
            </p>
            <p>
              If you unsubscribe from receiving text messages from BEAUTISTA
              through the process described above, you may continue to receive
              text messages for a short period while BEAUTISTA processes your
              request(s). If you change or deactivate the phone number you
              provided to BEAUTISTA , you have an affirmative obligation to
              immediately update your account information and the phone
              number(s) attached to your account to prevent us from
              inadvertently communicating with anyone who acquires any phone
              number(s) previously attributed to you, and any new phone
              number(s) you attach to your Account may receive BEAUTISTA ’s
              standard SMS or MMS messages unless you also unsubscribe via the
              above procedures.
            </p>
            <p>
              Standard data and message rates may apply for SMS and MMS alerts,
              whether you send or receive such messages. Please contact your
              mobile phone carrier for details. Your mobile phone carrier (e.g.
              T-Mobile, AT&T, Verizon, etc.) is not liable for delayed or
              undelivered messages. If you require assistance, please call our
              Customer Care team at 888-299-8921
            </p>

            <h3>Additional Terms for Mobile Applications</h3>
            <p>
              We may make available software to access BEAUTISTA ’s websites,
              technology platforms, and related online and mobile services via a
              mobile device (“Mobile Applications”). To use any Mobile
              Application, you must use a mobile device that is compatible with
              that Mobile Application. BEAUTISTA does not warrant that any
              Mobile Application will be compatible with your mobile device. You
              may use mobile data in connection with the Mobile Applications and
              may incur additional charges from your wireless provider for these
              services. You agree that you are solely responsible for any such
              charges. BEAUTISTA hereby grants you a non-exclusive,
              non-transferable, revocable license to use a compiled code copy of
              the Mobile Applications for one Account on a mobile device owned
              or leased solely by you, for your personal use. You acknowledge
              that BEAUTISTA may from time-to-time issue upgraded versions of
              the Mobile Applications, and may automatically electronically
              upgrade the version of any Mobile Applications that you are using
              on your mobile device. You consent to such automatic upgrading on
              your mobile device and agree to timely upgrade the Mobile
              Application in the event there is no automatic update. Please
              upgrade to the latest version of the Mobile Applications in order
              to view the most up-to-date information on the Services regarding
              Merchants featured on the Mobile Applications. You further agree
              that the terms and conditions of this Agreement will apply to all
              upgrades to the Mobile Applications. Any third-party code that may
              be incorporated in the Mobile Applications is covered by the
              applicable open source or third-party license EULA, if any,
              authorizing use of such code. The foregoing license grant is not a
              sale of the Mobile Applications or any copy thereof, and BEAUTISTA
              or its third-party partners or suppliers retain all right, title,
              and interest in the Mobile Applications (and any copy thereof).
              Any attempt by you to transfer any of the rights, duties, or
              obligations hereunder, except as expressly provided for in this
              Agreement, is void. BEAUTISTA reserves all rights not expressly
              granted under this Agreement with respect to the Mobile
              Applications and otherwise. If any Mobile Application is being
              acquired on behalf of the United States Government, then the
              following provision applies: The Mobile Application will be deemed
              to be “commercial computer software” and “commercial computer
              software documentation,” respectively, pursuant to DFAR § 227.7202
              and FAR § 12.212, as applicable. Any use, reproduction, release,
              performance, display, or disclosure of the Services and any
              accompanying documentation by the U.S. Government will be governed
              solely by this Agreement and is prohibited except to the extent
              expressly permitted by this Agreement. The Mobile Applications
              originate in the United States and are subject to United States
              export laws and regulations. The Mobile Applications may not be
              exported or re-exported to certain countries or those persons or
              entities prohibited from receiving exports from the United States.
              In addition, the Mobile Applications may be subject to the import
              and export laws of other countries. You agree to comply with all
              United States and foreign laws related to use of the Mobile
              Applications and the Services.
            </p>
            <p>
              The following applies to any Mobile Application you acquire from
              the Apple App Store (“Apple-Sourced Software”): You acknowledge
              and agree that this Agreement is solely between you and BEAUTISTA,
              not Apple, Inc. (“Apple”) and that Apple has no responsibility for
              the Apple-Sourced Software or content thereof. Your use of the
              Apple-Sourced Software must comply with the App Store Terms of
              Service. You acknowledge that Apple has no obligation whatsoever
              to furnish any maintenance and support services with respect to
              the Apple-Sourced Software. In the event of any failure of the
              Apple-Sourced Software to conform to any applicable warranty, you
              may notify Apple, and Apple will refund to you the purchase price
              for the Apple-Sourced Software; to the maximum extent permitted by
              applicable law, Apple will have no other warranty obligation
              whatsoever with respect to the Apple-Sourced Software, and any
              other claims, losses, liabilities, damages, costs, or expenses
              attributable to any failure to conform to any warranty will be
              solely governed by this Agreement and any law applicable to CANVAS
              ME as provider of the software. You acknowledge that Apple is not
              responsible for addressing any of your claims or those of any
              third party relating to the Apple-Sourced Software or your
              possession and/or use of the Apple-Sourced Software, including,
              but not limited to: (i) product liability claims; (ii) any claim
              that the Apple-Sourced Software fails to conform to any applicable
              legal or regulatory requirement; and (iii) claims arising under
              consumer protection, privacy or similar legislation; and all such
              claims are governed solely by this Agreement and any law
              applicable to BEAUTISTA as provider of the software. You
              acknowledge that, in the event of any third-party claim that the
              Apple-Sourced Software or your possession and use of that
              Apple-Sourced Software infringes that third party’s intellectual
              property rights, BEAUTISTA , not Apple, will be solely responsible
              for the investigation, defense, settlement, and discharge of any
              such intellectual property infringement claim to the extent
              required by this Agreement. You and BEAUTISTA acknowledge and
              agree that Apple, and Apple’s subsidiaries, are third party
              beneficiaries of this Agreement as it relates to your license of
              the Apple-Sourced Software, and that, upon your acceptance of the
              terms and conditions of this Agreement, Apple will have the right
              (and will be deemed to have accepted the right) to enforce this
              Agreement against you as a third-party beneficiary as it relates
              to your license of the Apple-Sourced Software.
            </p>
            <p>
              The following applies to any Mobile Applications you acquire from
              the Google Play Store (“Google-Sourced Software”): (i) you
              acknowledge that the Agreement is solely between you and BEAUTISTA
              only, and not with Google, Inc. (“Google”); (ii) your use of
              Google-Sourced Software must comply with Google’s then-current
              Google Play Store Terms of Service; (iii) Google is only a
              provider of the Google Play Store where you obtained the
              Google-Sourced Software; (iv) BEAUTISTA , and not Google, is
              solely responsible for its Google-Sourced Software; (v) Google has
              no obligation or liability to you with respect to Google-Sourced
              Software or the Agreement; and (vi) you acknowledge and agree that
              Google is a third-party beneficiary to the Agreement as it relates
              to BEAUTISTA ’s Google-Sourced Software.
            </p>

            <h3>Your Comments and Concerns</h3>
            <p>
              This website is operated by Beautiful Canvas, LLC, PO Box 469
              Florham Park, NJ 07932.
            </p>
            <p>
              All notices of copyright infringement claims should be sent to the
              copyright agent designated in the Copyright Policy above in the
              manner and by the means set out therein.
            </p>
            <p>
              All other feedback, comments, requests for technical support, and
              other communications relating to the Website should be directed
              to: <a href="mailto:hello@beautista.com">hello@beautista.com</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
