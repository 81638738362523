import React from "react";
import { Link } from "react-router-dom";
import { Button, Breadcrumb, Checkbox } from "antd";
import IconCrown from "../../../assets/images/ic_crown.svg";
import {
  GetUserInfoAction,
  module_my_canvas,
  ModelMyCanvases,
} from "../../../models/model_my_canvas";
import _ from "lodash";
import { doAction, mapProp } from "module-reaction";
import "./add_canvas.less";
import {
  TargetType,
  router_company_info,
  router_professional_canvas_create,
  router_school_info,
  router_student_canvas_create,
} from "../../../utils/enum";

interface CanvasOptionProps {
  hidden?: boolean;
  checked?: boolean;
  title: string;
  subtitle: string;
  videoUrl?: string;
  isPremium?: boolean;
  onCheckChange?: Function;
}

const CanvasOption: React.FC<CanvasOptionProps> = (props) => {
  const {
    hidden = false,
    checked = false,
    title,
    subtitle,
    isPremium = false,
    onCheckChange,
    videoUrl
  } = props;
  return (
    <div className="canvas-option" hidden={hidden}>
      <Checkbox
        className="check-box"
        checked={checked}
        onChange={(e) => onCheckChange && onCheckChange(e.target.checked)}
      />
      <div className="info-sec">
        <span className="premium premium-mobile" hidden={!isPremium}>
          <img alt="" src={IconCrown} />
          Premium
        </span>
        <span className="name">{title}</span>
        <span className="desc">{subtitle}</span>
        {videoUrl && (
          <span><a href={videoUrl} target="_blank" onClick={(e) => e.stopPropagation()}> Watch the Video</a></span>
        )}
      </div>
      <div className="space" />
      <span className="premium" hidden={!isPremium}>
        <img alt="" src={IconCrown} />
        Premium
      </span>
    </div>
  );
};

@mapProp(module_my_canvas)
export class AddCanvas extends React.Component<ModelMyCanvases, {}> {
  state = {
    curOption: 0,
  };

  componentDidMount() {
    if (!this.props.user || !this.props.user.profile) {
      doAction(GetUserInfoAction);
    }
  }

  onOptionSelect = (checked: boolean, id: number) => {
    if (!checked && this.state.curOption === id) {
      this.setState({ ...this.state, curOption: 0 });
    } else {
      this.setState({ ...this.state, curOption: id });
    }
  };

  onCreateClick = () => {
    let path;
    switch (this.state.curOption) {
      case 1:
        path = router_professional_canvas_create;
        break;
      case 2:
        path = router_company_info;
        break;
      case 3:
        path = router_school_info;
        break;
      default:
        path = router_student_canvas_create;
        break;
    }
    path && this.props.history.push(path);
  };

  render() {
    const canvasList = _.filter(this.props.canvasList, (item) => {
      return item.memberRole !== 1;
    });
    return (
      <div className="add-canvases-root">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/my-canvases">
              <span className="bread-crumb-item">My Portfolios</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="bread-crumb-item">Add New Portfolio</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <span className="title">Add New Portfolio</span>
        <div className="content">
          <span className="subtitle">I want to create:</span>
          <div className="options">
            <CanvasOption
              checked={this.state.curOption === 4}
              title="A Student Portfolio"
              subtitle="As a student, this free portfolio will help you market yourself through school to land the career opportunities of your dreams."
              onCheckChange={(checked: boolean) =>
                this.onOptionSelect(checked, 4)
              }
              hidden={!_.isEmpty(canvasList)}
            />
            <div className="divider" hidden={!_.isEmpty(canvasList)} />
            <CanvasOption
              checked={this.state.curOption === 1}
              title="A Personal/Professional Portfolio"
              subtitle="As a licensed professional or someone that works in the beauty industry, you can use your free portfolio like a website to connect, network, recruit and/or apply for all career enhancing opportunities including full-time, mentorships, brand educator roles and more."
              onCheckChange={(checked: boolean) =>
                this.onOptionSelect(checked, 1)
              }
              hidden={
                this.props.user.profile &&
                this.props.user.profile.target &&
                (this.props.user.profile.target === TargetType.STUDENT ||
                  this.props.user.profile.target === TargetType.PERSONAL) &&
                !_.isEmpty(canvasList)
              }
            />
            <div
              className="divider"
              hidden={
                this.props.user.profile &&
                this.props.user.profile.target &&
                (this.props.user.profile.target === TargetType.STUDENT ||
                  this.props.user.profile.target === TargetType.PERSONAL) &&
                !_.isEmpty(canvasList)
              }
            />
            <CanvasOption
              checked={this.state.curOption === 2}
              title="A Business Portfolio"
              subtitle="Your business portfolio serves as a mini landing page that highlights your business from a recruitment perspective. You can highlight videos and imagery of your team and showcase your culture in a way that attracts the right talent to your business. You will receive a free business portfolio when you purchase a career opportunity."
              isPremium
              onCheckChange={(checked: boolean) =>
                this.onOptionSelect(checked, 2)
              }
            />
            <div className="divider" />
            <CanvasOption
              checked={this.state.curOption === 3}
              isPremium
              title="A School Portfolio and Dashboard"
              subtitle="As a School owner or leadership team member you can leverage our BEAUTISTA Dashboards to empower your students to be seen through a digital portfolio versus word doc resume. You can share out their portfolio links and keep track of placement rate. Leverage BEAUTISTA portfolios for Placement Documentation."
              videoUrl="https://youtu.be/_pf22GGuAKE"
              onCheckChange={(checked: boolean) =>
                this.onOptionSelect(checked, 3)
              }
            />
          </div>
          <Button
            className="btn-primary btn-create"
            disabled={this.state.curOption === 0}
            onClick={() => this.onCreateClick()}
          >
            Create a new portfolio
          </Button>
        </div>
      </div>
    );
  }
}
