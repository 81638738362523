import React from "react";
import { getStartedViaSource, getStaticAssetPath } from "../../../utils/utils";
import { Button } from "antd";
import { scBaseUrl } from "../../../utils/constants";

interface Props {
  setCurPlayUrl: Function;
  ctaLabel?: string;
  source: string;
  headline?: string;
  content?: string;
  watchLink?: string;
  watchImagePath?: string;
  vidPath?: string;
  contentPosition?: string;
  icon?: string;
}

const StudentProWatch: React.FC<Props> = ({ 
  setCurPlayUrl, 
  ctaLabel = 'Join in less than 3 minutes', 
  source, 
  headline = 'Helping Beauty Professionals Get Discovered And Get Hired',
  content = 'Our mission at BEAUTISTA is to GIVE YOU A VOICE on a national scale so you can FIND YOUR PEOPLE.',
  watchLink = 'https://sites.canvasme.com/video/',
  watchImagePath = getStaticAssetPath('studentpro-landing/watch_preview.png'),
  vidPath = scBaseUrl + 'Canvas_Video_Pro_v3.mp4',
  contentPosition = 'right',
  icon = getStaticAssetPath('studentpro-landing/icons/works.png')
}) => {
  const contentStyle = contentPosition === 'left' ? {order: 1} : {order: 3};
  return (
    <div>
      <div className="cm-sp-watch-wrap cm-flex-responsive-row">
        <img className="cm-sp-watch-target" src={watchImagePath} alt="Watch Preview" onClick={() => setCurPlayUrl(vidPath)} />
        <div style={contentStyle} className="cm-sp-watch-content">
          <div className="cm-flex-row cm-flex-v-center">
            <img src={icon} alt="network" />
            <h2>{headline}</h2>
          </div>
          <p>{content}</p>
          <a href={watchLink}>Watch how it works.</a>
        </div>
      </div>
      <div className="cm-flex-row cm-flex-center cm-v-spacing">
        <Button className="btn-primary" onClick={() => getStartedViaSource(source)}>{ctaLabel}</Button>
      </div>
    </div>
  )
};

export default StudentProWatch;
