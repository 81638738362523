import React from "react";
import StudentProHeadline from "./studentpro_headline";
import { getStartedViaSource, getStaticAssetPath } from "../../../utils/utils";
import { Button } from "antd";

interface Props {
  ctaLabel?: string;
  source: string;
}

const StudentProThrive: React.FC<Props> = ({ ctaLabel = 'Join in less than 3 minutes', source}) => {
  const itemsLeft = [
    {
      id: 1,
      text: 'Create your free portfolio',
      image: 'create.png'
    },
    {
      id: 2,
      text: 'Upload imagery of your work',
      image: 'upload.png'
    },
    {
      id: 3,
      text: 'Upload videos of your work',
      image: 'video.png'
    }
  ];
  const itemsRight = [
    {
      id: 4,
      text: 'Share your BEAUTISTA link with anyone in the world.',
      image: 'share.png'
    },
    {
      id: 5,
      text: 'Generate your resume and QR code',
      image: 'resume.png'
    },
    {
      id: 6,
      text: 'Apply for opportunities with one click',
      image: 'apply.png'
    }
  ];
  return (
    <div className="cm-sp-thrive-root">
      <StudentProHeadline headline="How you can thrive with the BEAUTISTA movement." subtitle="When you join BEAUTISTA you are joining a community of elite beauty professionals driven to live out the beauty career of their dreams." />
      <div className="cm-flex-responsive-row cm-flex-center">
        <div className="cm-flex-column">
          {itemsLeft.map(item => (
            <div className="cm-flex-row cm-flex-v-center cm-sp-thrive-item">
              <img src={getStaticAssetPath(`studentpro-landing/icons/${item.image}`)} alt={item.text} />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
        <div className="cm-flex-column">
          {itemsRight.map(item => (
            <div className="cm-flex-row cm-flex-v-center cm-sp-thrive-item">
              <img src={getStaticAssetPath(`studentpro-landing/icons/${item.image}`)} alt={item.text} />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="cm-flex-row cm-flex-center cm-v-spacing">
        <Button className="btn-primary" onClick={() => getStartedViaSource(source)}>{ctaLabel}</Button>
      </div>
    </div>
  )
};

export default StudentProThrive;
