import React, { useState, useRef } from "react";
import "./home_testimonials.less";

import quoteRachel from "../../../assets/images/home/tavatar-rachel.jpg";
import quoteKate from "../../../assets/images/home/tavatar-kate.png";
import quoteKay from "../../../assets/images/home/tavatar-kay.png";
import quoteTail from "../../../assets/images/home/quote-tail.svg";
import quoteAshley from "../../../assets/images/home/tavater-ashley.png";
import quoteVictoria from "../../../assets/images/home/tavatar-victoria.jpg";

const ReadMore = ({ children }: any) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 160) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};

interface Props {
  isSalonCentricStopInterview?: boolean;
  isWellaLP?: boolean;
}

function VideoComponent() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const toggleVideoPlayback = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) { /* Firefox */
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) { /* IE/Edge */
        videoRef.current.msRequestFullscreen();
      }
    }
  };

  return (
    <div className="video-container">
      <video ref={videoRef} width="100%" onClick={toggleVideoPlayback}>
        <source
          src="https://d7mre4t3d00rw.cloudfront.net/assets/kim-layton-ogle-schools.mp4"
          type="video/mp4"
        />
      </video>
      {!isPlaying && (
        <div className="play-button" onClick={toggleVideoPlayback}>
          ►
        </div>
      )}
      <div className="video-overlay">
        <div className="text">Kim Layton</div>
        <div className="text">VP of Education</div>
        <div className="text">Ogle Schools</div>
        <div onClick={toggleFullScreen} className="fullscreen-button">⛶</div>
      </div>
    </div>
  );
}
export class HomeTestimonials extends React.Component<Props, {}> {
  render() {
    const { isSalonCentricStopInterview, isWellaLP } = this.props;
    return (
      <div className="cm-home-testimonials cm-flex-responsive-row">
        {isSalonCentricStopInterview ? (
          <div className="cm-home-testimonial">
            <div className="cm-flex-row cm-flex-center">
              <img
                className="cm-home-testimonial__avatar"
                alt="Kay"
                src={quoteAshley}
              />
            </div>
            <div className="cm-home-testimonial__inner">
              <p className="cm-home-testimonial__name">Ashley</p>
              <p className="cm-home-testimonial__title">
                Recruiter for Lords & Ladies
              </p>
              <p className="cm-home-testimonial__content">
                From the moment they apply until they are hired is all within
                days.
              </p>
            </div>
            <img
              className="cm-home-testimonial__tail"
              alt="tail"
              src={quoteTail}
            />
          </div>
        ) : (
          <div className="cm-home-testimonial">
            <div className="cm-flex-row cm-flex-center">
              <img
                className="cm-home-testimonial__avatar"
                alt="Kay"
                src={quoteKay}
              />
            </div>
            <div className="cm-home-testimonial__inner">
              <p className="cm-home-testimonial__name">Kay</p>
              <p className="cm-home-testimonial__title">Beauty Professional</p>
              <p className="cm-home-testimonial__content">
                You have no idea how absolutely thrilled I am to be a part of
                BEAUTISTA. It is a platform that stylists need in order to reach
                and achieve literal life-long dreams.
              </p>
            </div>
            <img
              className="cm-home-testimonial__tail"
              alt="tail"
              src={quoteTail}
            />
          </div>
        )}
        {isWellaLP ? (
          <>
            <div className="cm-home-testimonial">
              <div className="cm-flex-row cm-flex-center">
                <img
                  className="cm-home-testimonial__avatar"
                  alt="Kay"
                  src={quoteVictoria}
                />
              </div>
              <div className="cm-home-testimonial__inner">
                <p className="cm-home-testimonial__name">Victoria</p>
                <p className="cm-home-testimonial__title">
                  Director at The Wella Corporation
                </p>
                <p className="cm-home-testimonial__content">
                  Wella knows the need for talent has never been greater.
                  Finding the right fit for your salon team is essential to the
                  growth and success of the entire salon, BEAUTISTA is the
                  solution to help fill your talent gap!
                </p>
              </div>
              <img
                className="cm-home-testimonial__tail"
                alt="tail"
                src={quoteTail}
              />
            </div>
            <div className="cm-home-testimonial">
              <VideoComponent />
            </div>
          </>
        ) : (
          <>
            <div className="cm-home-testimonial">
              <div className="cm-flex-row cm-flex-center">
                <img
                  className="cm-home-testimonial__avatar"
                  alt="Rachel"
                  src={quoteRachel}
                />
              </div>
              <div className="cm-home-testimonial__inner">
                <p className="cm-home-testimonial__name">Rachel Wilkerson</p>
                <p className="cm-home-testimonial__title">
                  Professional Development Specialist
                  <br />
                  Paul Mitchell The School Columbus
                </p>
                <p className="cm-home-testimonial__content">
                  <ReadMore>
                    BEAUTISTA is truly a game changer for the Beauty Industry!
                    We FINALLY have a place we call home to promote ourselves,
                    our salons, network with our peers, search for opportunities
                    and level the playing field regardless of what city you call
                    home. For our Future Professionals in Columbus, Ohio to have
                    the opportunity to seek out work in sunny California, the
                    heart of Broadway or anywhere else they desire is an
                    incredible advancement in the industry. BEAUTISTA has been a
                    game changer in the way that I teach Personal Branding,
                    Resumes, Career Fairs and Portfolios because it’s ups the
                    game to being technologically current. Long gone are the
                    days of a coffee table portfolio and I’m saying see ya later
                    to the social media visual replacement because their Canvas
                    is the perfect encapsulation of their work, experience,
                    accolades, and knowledge combined into a beautiful package!
                  </ReadMore>
                </p>
              </div>
              <img
                className="cm-home-testimonial__tail"
                alt="tail"
                src={quoteTail}
              />
            </div>
            <div className="cm-home-testimonial">
              <div className="cm-flex-row cm-flex-center">
                <img
                  className="cm-home-testimonial__avatar"
                  alt="Kate"
                  src={quoteKate}
                />
              </div>
              <div className="cm-home-testimonial__inner">
                <p className="cm-home-testimonial__name">
                  Kate Oechsle-Truesdale
                </p>
                <p className="cm-home-testimonial__title">
                  AVP of Global Education at Mizani
                </p>
                <p className="cm-home-testimonial__content">
                  BEAUTISTA elevated our experience around Artist recruitment.
                  We successfully hired 33 NEW and amazing Artists to our team.
                  I would refer every school, salon, and stylist to join in a
                  heartbeat.
                </p>
              </div>
              <img
                className="cm-home-testimonial__tail"
                alt="tail"
                src={quoteTail}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}
