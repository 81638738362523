import React from "react";
import StudentProHeadline from "./studentpro_headline";
import StudentProCanvasTile from "./studentpro_canvas_tile";
import { getStartedViaSource, getStaticAssetPath } from "../../../utils/utils";
import { Button } from "antd";

interface Props {
  ctaLabel?: string;
  source: string;
}

const FeaturedOpportunities: React.FC<Props> = ({ ctaLabel = 'Join in less than 3 minutes', source }) => {
  const portfolios = [
    {
      name: "Assistant",      
      image: "assistant.png",
      type: "Full time",
      url: "https://www.beautista.com/opportunity-detail/elle-b-salon-assistant"
    },
    {
      name: "Front Desk",
      image: "frontdesk.png",
      type: "Full time",
      url: "https://www.beautista.com/opportunity-detail/elle-b-salon-front-desk"
    },
    {
      name: "Hairstylist",
      image: "hairstylist.png",
      type: "Full time",
      url: "https://www.beautista.com/opportunity-detail/elle-b-salon-hairstylist"
    },
    {
      name: "Educator",
      image: "educator.png",
      type: "Full time",
      url: "https://www.beautista.com/opportunity-detail/redken-redken-educator"
    },
  ];

  return (
    <div>
      <StudentProHeadline headline="Featured Career Opportunities." subtitle="BEAUTISTA empowers you to overcome your biggest hiring challenges in the beauty industry. Build, share, and manage your career opportunity page in seconds. Vet candidates through portfolios, not resumes, and cut your interview process in half." />
      <div className="cm-flex-row cm-flex-wrap cm-flex-space">
        { portfolios.map(p => <StudentProCanvasTile visualization='opportunity' name={p.name} image={getStaticAssetPath(`studentpro-landing/portfolios/${p.image}`)} type={p.type} url={p.url} /> )}
      </div>
      <div className="cm-flex-row cm-flex-center cm-v-spacing">
        <Button className="btn-primary" onClick={() => getStartedViaSource(source)}>{ctaLabel}</Button>
      </div>
    </div>
  )
};

export default FeaturedOpportunities;
