import { ModuleStore, ModuleAction, plusAction, KV } from "module-reaction";
import { getCustomized } from "../api/Options";
import { signUp, completeUserInfo, completeSignup, verifyPhoneCode, skipVerifyCode, addUserSelectedSource } from "../api/Auth";
import { TargetType } from "../utils/enum";
import { global_router } from "../routers";
import { MODULE_HOME } from "./model_home";
import _ from "lodash";
import { LoginWithTokenAction, MODULE_USER } from "./model_user";
import { changeUser, chatClient } from "../utils/chatHelper";
import { signUpPathFromTarget } from "../utils/utils";
import store from "../utils/store";
import { message } from "antd";
import { getToken } from "../utils/authStorage";

export const MODULE_GET_STARTED = "module_get_started";

export interface ModuleIdName extends ModuleStore {
  id: number;
  name: string;
}

export interface ModuleGetStarted extends ModuleStore {
  user_roles: ModuleIdName[];
  permits: ModuleIdName[];
  program: ModuleIdName[];
  licenseCategory: ModuleIdName[];
  employStatus: ModuleIdName[];
  ipCategory: ModuleIdName[];
  studentCategory: ModuleIdName[];
  jobTitle: ModuleIdName[];

  // pageName: "signup_main" | "signup_email" | "info" | "profile" | "verifyCode";
  pageName: "source" | "signup_main" | "info" | "profile" | "verifyCode" | "join";
}

export const module_get_started: ModuleGetStarted = {
  module: MODULE_GET_STARTED,
  user_roles: [
    { id: 0, name: "Student" },
    { id: 1, name: "Licensed Pro" },
    { id: 2, name: "Not Licensed Industry Pro" },
  ] as ModuleIdName[],
  permits: [
    // { id: 0, name: "I have a work permit" }, // remove on bug-sheet of id=800
    { id: 1, name: "I am working towards my permit" },
    { id: 2, name: "permits don't apply to my state" },
  ] as ModuleIdName[],
  program: [],
  licenseCategory: [],
  ipCategory: [],
  employStatus: [
    { id: 0, name: "employed" },
    { id: 1, name: "not employed" },
  ] as ModuleIdName[],
  studentCategory: [],
  jobTitle: [],

  pageName: "signup_main",
};

export const GetSelectionData: ModuleAction<any, ModuleGetStarted> = {
  module: MODULE_GET_STARTED,
  process: async () => {
    const result = await getCustomized(true, [
      "program",
      "category",
      "jobTitle",
    ]);
    return {
      program: result.program,
      licenseCategory: result.category.beautyPro,
      ipCategory: result.category.industryProfessional,
      studentCategory: result.category.student[0],
      jobTitle: result.jobTitle,
    };
  },
};

export const SignUpAction: ModuleAction<any, any> = {
  module: MODULE_GET_STARTED,
  process: async (params: any) => {
    const res = await signUp(params);
    if (res && res.access_token) {
      store.dispatch({ type: 'SET_GET_STARTED_BUTTON_CLICKED_FALSE' });
      return { pageName: "source" };
    }
    store.dispatch({ type: 'SET_GET_STARTED_BUTTON_CLICKED_FALSE' });
    return {};
  },
};

export const AddUserSelectedSource: ModuleAction<any, any> = {
  module: MODULE_GET_STARTED,
  process: async (payLoad: any) => {
    const response = await addUserSelectedSource(payLoad)
    console.log(response)
    plusAction(MODULE_GET_STARTED, { pageName: response.pageName });
    return {}
  }
}

export const CompleteSignupAction: ModuleAction<any, any> = {
  module: MODULE_GET_STARTED,
  process: async (payload: any) => {
    const userCategories = _.map(payload.category, (item) => {
      let cate = { categoryId: item.id, name: item.name } as any;
      if (item.license) {
        cate = { ...cate, licenseNumber: item.license };
      }
      return cate;
    });
    let params: any = {
      career: payload.role,
      categories: userCategories,
      target: payload.target,
      source: payload.source
    };
    if (payload.permit) {
      params.workPermitStatus = payload.permit;
    }
    if (payload.enrolls.length) {
      params.programId = payload.enrolls[0].id;
      if (payload.enrolls.length > 1) {
        params.program2Id = payload.enrolls[1].id;
      }
    }
    if (payload.employ) {
      params.employmentStatus = payload.employ;
    }

    const res = await completeSignup(params);
    if (res?.user) {
      if (chatClient) {
        await changeUser(res.user)
      }
      plusAction(MODULE_HOME, { user: res.user });
      plusAction(MODULE_GET_STARTED, { pageName: "profile" });
    }
    return {};
  },
};

export const CompleteUserInfoAction: ModuleAction<any, ModuleGetStarted> = {
  module: MODULE_GET_STARTED,
  process: async (data: any, module: ModuleGetStarted) => {
    const {params, skipVerify, email} = data;
    const res = await completeUserInfo(params);

    await checkOauthViaToken(email)

    if (res?.user) {
      plusAction(MODULE_USER, { curUser: res.user });
      if (skipVerify) {
        plusAction(SkipVerifyAction, {target: res.user.profile.target})
      } else {
        return { pageName: 'verifyCode' }
      }
    }

    return {}
  },
};

export const checkOauthViaToken = async (email: string) => {
  const query = new URL(window.location.href);
  const redirect_uri = query.searchParams.get("redirect_uri");
  const state = query.searchParams.get("state");
  if (redirect_uri && state) {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/oauth/authorize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify({
          email
        })
      });

      if (response.ok) {
        const data = await response.json();
        window.location.href = `${redirect_uri}?code=${data.code}&state=${state}`;
      } else {
        message.error('Login failed');
      }
    } catch (err) {
      console.error('OAuth login error:', err);
      message.error('Login failed');
    }
  }
}

export const FinishVerifyCodeAction: ModuleAction = {
  module: MODULE_GET_STARTED,
  process: async (payload: KV) => {
    const { phone, code, target } = payload
    const res = await verifyPhoneCode(phone, code)
    if (res?.success) {
      const path = signUpPathFromTarget(target);
      plusAction(LoginWithTokenAction)

      global_router.history.push(path);

      return module_get_started; // reset

    }
    return {}
  }
}

export const SkipVerifyAction: ModuleAction = {
  module: MODULE_GET_STARTED,
  process: async (payload: KV) => {
    const { phone, target } = payload
    const res = await skipVerifyCode(phone)
    if (res?.success) {
      const path = signUpPathFromTarget(target);
      plusAction(LoginWithTokenAction)

      global_router.history.push(path);

      return module_get_started; // reset
    }
    return {}
  }

}
