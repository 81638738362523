import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { TextField, MenuItem } from "@material-ui/core";
import _ from "lodash";
import queryString from "query-string";
import cloneDeep from "lodash/cloneDeep";
import HorizontalScrollView from "../../../components/component_horizontal_scroll_view";
import { SchoolDashboardStats } from "./school_dashboard_stats";
import { SchoolDashboardStudents } from "./school_dashboard_students";
import { SchoolDashboardTeam } from "./school_dashboard_team";
import SchoolDashboardAddStudent from "./school_dashboard_add_student";
import { MyAccountPostedOpp } from "../dashboard_opportunity/my_account_posted_opportunities";
import classnames from "classnames";
import "./school_dashboard_index.less";
import {
  modle_user,
  ModelUser,
  LoginWithTokenAction,
} from "../../../models/model_user";
import { mapProp, doAction, doFunction } from "module-reaction";
import { chatClient, initChatClient } from "../../../utils/chatHelper";
import { router_school_payment_with_id, SchoolRole } from "../../../utils/enum";
import { getRoleName } from "../../../components/component_employee_gridview";
import { freshDashboardAction } from "../../../models/model_dashboard_index";
import { Button } from "antd";
import iconNote from '../../../assets/images/ic_big_note.svg'

interface SchoolDashboardProps extends ModelUser, RouteComponentProps { }

@mapProp(modle_user)
export class SchoolDashboardIndex extends React.Component<
SchoolDashboardProps,
{}
> {
  get curSchool() {
    return this.props.curCanvas;
  }

  get dashboardRouters() {
    const curCanvas: any = this.curSchool;
    const curCanvasPaid = this.props?.curCanvasPaid
    if (!curCanvasPaid) {
      return []
    }
    return [
      {
        name: "My Team",
        component: () => (
          <SchoolDashboardTeam
            school={curCanvas}
            changeTab={this.changeTab}
            history={this.props.history}
            curUser={this.props.curUser}
          />
        ),
        hidden:
          curCanvas &&
          curCanvas.memberRole &&
          curCanvas.memberRole !== SchoolRole.ADMIN,
        hiddenByCanvas:
          curCanvas &&
          curCanvas.memberRole &&
          curCanvas.memberRole !== SchoolRole.ADMIN,
      },
      {
        name: "My Students",
        component: () => (
          <SchoolDashboardStudents
            school={curCanvas}
            changeTab={this.changeTab}
            history={this.props.history}
            curUser={this.props.curUser}
          />
        ),
      },

      {
        name: "Add Students",
        component: () => (
          <SchoolDashboardAddStudent
            school={curCanvas}
            changeTab={this.changeTab}
          />
        ),
        hidden: true,
      },
      {
        // name: curCanvas && curCanvas.memberRole && curCanvas.memberRole === SchoolRole.ADMIN ? "Available Opportunities" : "Posted Opportunities",
        name: "Posted Opportunities",
        component: () => (
          <MyAccountPostedOpp
            canvasInfo={curCanvas}
            history={this.props.history}
          ></MyAccountPostedOpp>
        ),
        hidden:
          curCanvas &&
          curCanvas.memberRole &&
          curCanvas.memberRole !== SchoolRole.ADMIN,
        hiddenByCanvas:
          curCanvas &&
          curCanvas.memberRole &&
          curCanvas.memberRole !== SchoolRole.ADMIN,
      },
      {
        name: "Stats",
        component: () => (
          <SchoolDashboardStats
            school={curCanvas}
            history={this.props.history}
          />
        ),
      },
    ];
  }

  state = {
    curTab: _.last(this.dashboardRouters),
  };

  changeTab = (name: string) => {
    this.setState({
      curTab: _.find(this.dashboardRouters, { name }),
    });
  };

  tabType: any = {
    posted_opportunity: "Posted Opportunities",
    team: "My Team",
    students: "My Students",
  };

  componentDidMount() {
    if (!this.props.curUser || !this.props.curUser.id) {
      doAction(LoginWithTokenAction);
    }
    doFunction(async () => {
      if (!chatClient) {
        await initChatClient(this.props.curUser);
      }
    });
    this.initCurTab();
  }

  private initCurTab = () => {
    const searchObj: any = queryString.parse(window.location.search);
    const dashboardRouters = this.dashboardRouters;
    if (searchObj && searchObj.tabType && this.tabType[searchObj.tabType]) {
      const router = _.find(dashboardRouters, {
        name: this.tabType[searchObj.tabType],
      });
      router && this.setState({ curTab: router });
      // } else if (!dashboardRouters.some(_ => !_.hidden && _.name === this.state.curTab?.name)) {
    } else if (
      !dashboardRouters.some(
        (_) => !_.hiddenByCanvas && _.name === this.state.curTab?.name
      )
    ) {
      this.setState({ curTab: _.last(dashboardRouters) });
    }
  };
  el: any = null;

  schoolPayTip = () => {
    return <div className="school-pay-tip">
      <div className="tip-content">
        <img src={iconNote} alt="note" />
        <div className="tip-title">Subscribe to School plan to use the dashboard</div>
        <div className="tip-des">This canvas profile has not subscribed to a paid plan. Please process your payment to see your dashboard.</div>
        <Button 
        className="btn-primary btn-pay"
        onClick={e => this.props.history.push(router_school_payment_with_id.replace(':id', this.curSchool.id))}
        >Subscribe</Button>
      </div>
    </div>
  }

  render() {
    const comp = this.dashboardRouters
      .find((t) => t.name === this.state.curTab?.name)
      ?.component();
    if (this.state.curTab && !comp) {
      this.setState({curTab: _.last(this.dashboardRouters)})
    }
    const { curCanvas, canvasList } = this.props;
    let schoolSelect = 0;
    if (curCanvas) {
      schoolSelect = canvasList.findIndex((s: any) => s.id === curCanvas.id);
    }
    const tabs = cloneDeep(this.dashboardRouters)
      .reverse()
      .map((item: any, index: number) =>
        item.hidden ? null : (
          <span
            className={classnames("tab-btn tab-item", {
              active: this.state.curTab && item.name === this.state.curTab.name,
            })}
            key={index}
            onClick={() => this.setState({ curTab: item })}
          >
            {item.name}
          </span>
        )
      ).filter(Boolean);
    return (
      <div className="school-dashboard-wrap">
        <div className="school-dashboard-tab-wrap">
          <div className="tab-list">
            <HorizontalScrollView className="scroll" hideNavi={tabs.length === 0}>
              {tabs}
            </HorizontalScrollView>
          </div>
          <TextField
            label="Dashboard"
            variant="filled"
            select
            className="material-input input"
            value={"" + schoolSelect || ""}
            onChange={(e) => {
              const curCanvas = this.props.canvasList[e.target.value];
              doAction(freshDashboardAction, curCanvas);
            }}
          >
            {this.props.canvasList.map((item: any, index: number) => (
              <MenuItem key={index} value={"" + index}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          {this.curSchool?.memberRole && (
            <div className="member-role">
              View as {getRoleName(this.curSchool.memberRole)}
            </div>
          )}
        </div>

        {this.props.curCanvasPaid ? comp : this.schoolPayTip()}
      </div>
    );
  }
}
