import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import classnames from "classnames";
import IconFacebook from "../assets/images/ic_facebook.svg";
import IconTiktok from "../assets/images/ic_tiktok.svg";
import IconYoutube from "../assets/images/ic_youtube.svg";
import IconIns from "../assets/images/ic_instagram.svg";
import IconBtnLink from "../assets/images/ic_btn_link.svg";
import IconBtnUnLink from "../assets/images/ic_btn_unlink.svg";
import IconBtnSwitch from "../assets/images/ic_btn_switch.svg";
import "./component_social_media_item.less";
import { Button, Modal } from "antd";
import InstagramConnect from "./instagram/InstagramConnect";

export interface ISocialMedia {
  id?: number;
  type: number; //0:@; 2:url;
  label: string;
  value?: string;
}

interface Props {
  className?: string;
  data: ISocialMedia;
  onInput: Function;
  onClickInstagram?: Function;
  onDisconnectInstagram?: Function;
  portfolioId?: number;
}

const SocialMediaItem: React.FC<Props> = (props) => {
  const { className = "", data, onInput, onClickInstagram, onDisconnectInstagram, portfolioId } = props;
  const [onFocus, setOnFocus] = useState(false);
  const [showInstaModal, setShowInstaModal] = useState(false)
  const [showInstaDisconnectModal, setShowInstaDisconnectModal] = useState(false)

  const getIconByName = () => {
    if (!data) return "";
    switch (data.label) {
      case "youtube":
        return IconYoutube;
      case "facebook":
        return IconFacebook;
      case "tiktok":
        return IconTiktok;
      default:
        return IconIns;
    }
  };

  return (
    data.label === 'instagram' ? (
      <>

      <InstagramConnect portfolioId={portfolioId} />

      {/* <div className={classnames(className, "social-intsa-section", "social-media-item")}>
        <img alt="" src={getIconByName()} className={data.value ? "icon" : "big-icon"} />
        {
          data.value ? <TextField
            key={data.label}
            label={
              onFocus || data.value ? data.label : data.type === 0 ? "@" : "URL"
            }
            variant="filled"
            className="material-input input"
            value={data.value || ""}
          /> : (
              <>
                <div className="title">Connect with instagram</div>
                <div className="des">In order to display your instagram on your Canvas porfolio, please click on the button below. It will redirect you to the instagram platform. If you’re not familiar with this flow, please view the instruction <a href="">here</a></div>

              </>
            )
        }
        <div className="insta-btns">

          <Button
            className="btn-secondary btn-insta"
            onClick={e => setShowInstaModal(true)}
          >
            <img src={ data.value ? IconBtnSwitch : IconBtnLink} alt="link" />
           &nbsp;&nbsp;{data.value ? 'Switch or reconnect instagram' : 'Connect with instagram'}
          </Button>
          <Button
            className="btn-secondary btn-insta"
            hidden={!data.value}
            onClick={e => setShowInstaDisconnectModal(true)}
          >
            <img src={IconBtnUnLink} alt="unlink" />
           &nbsp;&nbsp;Disconnect
        </Button>
        </div>

        <div className="other-item-tip">
          Connect with other social media
        </div>
        <Modal
          width={400}
          className="insta-modal"
          visible={showInstaModal}
          okText="OK, take me there"
          okButtonProps={{ className: "btn-primary" }}
          closable
          centered
          onCancel={() => setShowInstaModal(false)}
          footer={null}
        >
          <div className="modal-title">Redirecting to instagram</div>
          <div className="modal-des">In order to display your instagram on your Canvas porfolio, we will have to redirect you to the instagram platform. If you’re not familiar with this flow, please view the instruction <a href="">here</a></div>
          <div className="btns">
            <Button
              className="btn-primary"
              onClick={() => {
                setShowInstaModal(false)
                onClickInstagram && onClickInstagram()
              }}
            >
              OK, take me there
        </Button>
            <Button
              className="btn-secondary"
              onClick={() => setShowInstaModal(false)}
            >
              Cancel
        </Button>
          </div>
        </Modal>
        <Modal
          width={400}
          className="insta-modal"
          visible={showInstaDisconnectModal}
          okText="OK, take me there"
          okButtonProps={{ className: "btn-primary" }}
          closable
          centered
          onCancel={() => setShowInstaDisconnectModal(false)}
          footer={null}
        >
          <div className="modal-title">Disconnect instagram</div>
          <div className="modal-des">You are disconnecting your instagram from your Canvas profile. You can reconnect with Instagram by editing your Canvas profile and go to Social Media section. </div>
          <div className="btns">
            <Button
              className="btn-primary"
              onClick={() => {
                setShowInstaDisconnectModal(false)
                onDisconnectInstagram && onDisconnectInstagram()
              }}
            >
              Confirm
        </Button>
            <Button
              className="btn-secondary"
              onClick={() => setShowInstaDisconnectModal(false)}
            >
              Cancel
        </Button>
          </div>
        </Modal>
      </div> */}
      </>
    ) : (
        <div className={classnames(className, "social-media-item")}>
          <img alt="" src={getIconByName()} className="icon" />

          <TextField
            key={data.label}
            label={
              onFocus || data.value ? data.label : data.type === 0 ? "@" : "URL"
            }
            variant="filled"
            className="material-input input"
            value={data.value || ""}
            onChange={(e) => onInput(e.target.value)}
            onFocus={() => setOnFocus(true)}
            onBlur={() => setOnFocus(false)}
          />
        </div>)
  );
};

export default SocialMediaItem;
