import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { TextField, MenuItem } from "@material-ui/core";
import HorizontalScrollView from "../../../components/component_horizontal_scroll_view";
import _ from "lodash";
import queryString from "query-string";
import { MyOpportunities } from "../dashboard_opportunity/my_opportunities";
import cloneDeep from "lodash/cloneDeep";
import { modle_user, ModelUser } from "../../../models/model_user";
import classnames from "classnames";
import { mapProp, doAction } from "module-reaction";
import { freshDashboardAction } from "../../../models/model_dashboard_index";
interface OpportunityDashboardProps extends ModelUser, RouteComponentProps {}
@mapProp(modle_user)
export class OpportunityDashboardIndex extends React.Component<
  OpportunityDashboardProps
> {
  dashboardRouters = [
    {
      name: "My Opportunities",
      component: () => <MyOpportunities></MyOpportunities>,
      exact: true,
      showMenu: true,
    },
  ];

  state = {
    curTab: this.dashboardRouters[0],
  };

  tabType: any = {
    posted_opportunity: "Posted Opportunities",
  };

  selectedCanvas = () => this.props.curCanvas || {};

  componentDidMount() {
    const searchObj: any = queryString.parse(window.location.search);
    if (searchObj && searchObj.tabType && this.tabType[searchObj.tabType]) {
      const router = _.find(this.dashboardRouters, {
        name: this.tabType[searchObj.tabType],
      });
      router && this.setState({ ...this.state, curTab: router });
    }
  }

  componentDidUpdate() {
    this.initCurTab();
  }

  private initCurTab = () => {
    if (!this.state.curTab?.name) {
      this.setState({ curTab: _.last(this.dashboardRouters) });
    }
  };

  render() {
    const { curCanvas, canvasList } = this.props;
    let canvasSelect = 0;
    if (curCanvas) {
      canvasSelect = canvasList.findIndex((s: any) => s.id === curCanvas.id);
    }
    const tabs = cloneDeep(this.dashboardRouters)
      .reverse()
      .map((item: any, index: number) => (
        <span
          className={classnames("tab-btn tab-item", {
            active: this.state.curTab && item.name === this.state.curTab.name,
          })}
          key={index}
          onClick={() => {
            this.setState({ ...this.state, curTab: item });
          }}
        >
          {item.name}
        </span>
      ));
    return (
      <div className="school-dashboard-wrap">
        <div className="school-dashboard-tab-wrap">
          <div className="tab-list">
            <HorizontalScrollView className="scroll">
              {tabs}
            </HorizontalScrollView>
          </div>
          <TextField
            label="Dashboard"
            variant="filled"
            select
            className="material-input input"
            value={"" + canvasSelect || ""}
            onChange={(e) => {
              const curCanvas = this.props.canvasList[e.target.value];
              doAction(freshDashboardAction, curCanvas);
            }}
          >
            {this.props.canvasList.map((item: any, index: number) => (
              <MenuItem key={index} value={"" + index}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {this.state.curTab && this.state.curTab.component()}
      </div>
    );
  }
}
